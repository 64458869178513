import React, { FC } from "react";
import styles from "./CurrencyTableItem.module.scss";
import { AgencyType } from "../../../../agency/types";
import LinkButton from "../../../../../components/LinkButton/LinkButton";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button/Button";
import { useModal } from "../../../../../components/Modal/useModal";
import AuthModal from "../../../../auth/components/AuthModal/AuthModal";

type CurrencyTableItemProps = {
  selectedCurrency: string | null;
  isAuth?: boolean;
} & AgencyType;

const CurrencyTableItem: FC<CurrencyTableItemProps> = (props) => {
  const { t } = useTranslation();

  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          <div className={styles.agency}>
            <div className={styles.agency__icon}>{props.name[0]}</div>
            <div className={styles.agency__content}>
              <p className={styles.agency__title}>{props.name}</p>
            </div>
          </div>
          <div className={styles.fx}>
            <span className={styles.item__mobile__title}>
              USD / {props.selectedCurrency}:
            </span>
            <span className={styles.item__mobile__content}>
              {props.exchangeRate}
            </span>
          </div>
          <div className={styles.commission}>
            <span className={styles.item__mobile__title}>
              {t("agencies.commission")}:
            </span>
            <span className={styles.item__mobile__content}>
              {props.commission}
            </span>
          </div>
          <div className={styles.commission}>
            <span className={styles.item__mobile__title}>
              Platform Commission:
            </span>
            <span className={styles.item__mobile__content}>
              {props.aquaCommission}
            </span>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.payout}>
            {props.paymentChannels.map((item) => {
              return (
                <span key={item.id} className={styles.payout__item}>
                  {item.name}
                </span>
              );
            })}
          </div>
          <div className={styles.payment}>
            {props.isAuth ? (
              <LinkButton
                to={`/transaction/${props.id}?currency=${props.selectedCurrency}`}
                buttonClassName={styles.selectBtn}
                variant={"secondary"}
              >
                {t("common.select")}
              </LinkButton>
            ) : (
              <Button
                onClick={openModal}
                className={styles.selectBtn}
                variant={"secondary"}
              >
                {t("common.select")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <AuthModal open={open} onClose={closeModal} />
    </>
  );
};

export default React.memo(CurrencyTableItem);
