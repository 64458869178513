import { ReactNode } from "react";
import { Icons } from "../../constants/icons";
import { PagesRoutes } from "../../features/navigation/constants";

type AgencyDashboardMenuItemType = {
  icon: ReactNode;
  text: string;
  to: string;
};

export const agencyDashboardMenuData: AgencyDashboardMenuItemType[] = [
  {
    icon: <Icons.Document />,
    text: "agency.dashboard.info",
    to: PagesRoutes.AGENCY.AGENCY_ADMIN_INFORMATION,
  },
  {
    icon: <Icons.Dollar />,
    text: "agency.dashboard.details",
    to: PagesRoutes.AGENCY.AGENCY_ADMIN_CURRENCY_DETAILS,
  },
  {
    icon: <Icons.Edit />,
    text: "agency.dashboard.history",
    to: PagesRoutes.AGENCY.AGENCY_ADMIN_TRANSACTION_HISTORY,
  },
  {
    icon: <Icons.Analytics />,
    text: "agency.dashboard.analytics",
    to: PagesRoutes.AGENCY.AGENCY_ADMIN_ANALYTICS,
  },
  {
    icon: <Icons.Home />,
    text: "agency.dashboard.home",
    to: PagesRoutes.COMMON.MAIN,
  },
];
