import React from "react";
import styles from "./CurrencyTable.module.scss";
import cn from "classnames";
import Skeleton from "../../../../components/Skeleton/Skeleton";

const CurrencyTableSkeleton = () => {
  return (
    <div className={cn(styles.wrapper, styles.skeleton)}>
      <Skeleton variant={"rectangular"} className={styles.skeleton__header} />
      {Array.from({ length: 4 }).map((item, index) => {
        return (
          <Skeleton
            variant={"rectangular"}
            className={styles.skeleton__item}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default CurrencyTableSkeleton;
