import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// this for case when user manual delete localStorage value or block localStorage
if (
  !localStorage.getItem("i18nextLng") ||
  localStorage.getItem("i18nextLng") === "dev"
) {
  localStorage.setItem("i18nextLng", "en");
}

i18n
  // Подключение бэкенда i18next
  .use(Backend)
  // Автоматическое определение языка
  .use(LanguageDetector)
  // модуль инициализации
  .use(initReactI18next)
  .init({
    // Стандартный язык
    // fallbackLng: "en",
    debug: false,
    // Распознавание и кэширование языковых кук
    detection: {
      order: ["localStorage", "cookie"],
      cache: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
