import React, { useEffect } from "react";
import { useModal } from "../../Modal/useModal";
import SuccessModal from "./SuccessModal";

type useSuccessModalType = {
  text: string;
};

export const useSuccessModal = ({ text }: useSuccessModalType) => {
  const { open, openModal, closeModal } = useModal();

  useEffect(() => {
    const timer = setTimeout(() => {
      closeModal();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [open, closeModal]);

  const successModalElement = (
    <SuccessModal open={open} onClose={closeModal} text={text} />
  );

  return {
    openModal,
    successModalElement,
  };
};
