import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchAgencyApiParams, FetchAgencyApiResponse } from "./types";

export const fetchAgencyApiMethod = ApiMethods.GET;

export const fetchAgencyApi = (
  params: FetchAgencyApiParams,
): Promise<FetchAgencyApiResponse> =>
  appAxiosInstance[fetchAgencyApiMethod]<FetchAgencyApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyApi,
    {
      params,
    },
  ).then((res) => res.data);
