import React, { FC, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import StreamerPersonalPaymentsEmpty from "./components/StreamerPersonalPaymentsEmpty/StreamerPersonalPaymentsEmpty";
import StreamerPersonalPaymentsItem from "./components/StreamerPersonalPaymentsItem/StreamerPersonalPaymentsItem";
import styles from "./StreamerPersonalPayments.module.scss";
import { useTranslation } from "react-i18next";
import { paymentQueries } from "../../../../../../features/payment/api/queries";
import LinkButton from "../../../../../../components/LinkButton/LinkButton";
import { PagesRoutes } from "../../../../../../features/navigation/constants";
import Loader from "../../../../../../components/Loader/Loader";

type StreamerPersonalPaymentsProps = {};

const StreamerPersonalPayments: FC<StreamerPersonalPaymentsProps> = () => {
  const { t, i18n } = useTranslation();

  const { data: payments, isLoading } = useQuery(
    paymentQueries.personalList({
      lang: i18n.language,
    }),
  );

  const renderContent = useMemo(() => {
    if (payments && payments.length) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.list}>
            {payments.map((item) => {
              return <StreamerPersonalPaymentsItem key={item.id} {...item} />;
            })}
          </div>
          <LinkButton
            to={PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT_LIST}
            className={styles.btn}
          >
            {t("streamer.payments.addMethod")}
          </LinkButton>
        </div>
      );
    }

    return <StreamerPersonalPaymentsEmpty />;
  }, [payments, t]);

  if (isLoading) {
    return <Loader />;
  }

  return renderContent;
};

export default React.memo(StreamerPersonalPayments);
