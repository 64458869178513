export const UserPaths = {
  fetchPersonalInfoApi: "/streamer/info",
  fetchGemsBalanceApi: "/streamer/gems/balance",
  updatePersonalInfoApi: "/streamer/info",
  logoutUser: "/logout",
  fetchTransactionHistory: "/streamer/payout-transactions",
  fetchTransactionHistoryDetails: "/streamer/payout-transactions/id",
  updateTransactionStatus: "/streamer/payout-transactions/id/completed",
  deletePaymentChannelApi: "/streamer/payment-channels/info",
  fetchUserTransactionStatusList: "/streamer/payout-transactions/status",
};
