import React, { FC } from "react";
import { CurrencyType } from "../../../../types";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";
import styles from "./CurrencyFilterItem.module.scss";

type CurrencyFilterItemProps = {
  isActive?: boolean;
} & CurrencyType;

const CurrencyFilterItem: FC<CurrencyFilterItemProps> = ({
  isoCode,
  name,
  isActive,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCurrencyItemClick = (code: CurrencyType["isoCode"]) => {
    searchParams.set("currency", code);
    setSearchParams(searchParams);
  };

  return (
    <button
      className={cn(styles.item, {
        [styles.active]: isActive,
      })}
      onClick={() => handleCurrencyItemClick(isoCode)}
    >
      {isoCode}
    </button>
  );
};

export default CurrencyFilterItem;
