import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { UpdateAgencyInfoParams, UpdateAgencyInfoResponse } from "./types";

export const updateAgencyInfoApiMethod = ApiMethods.POST;

export const updateAgencyInfoApi = (
  params: UpdateAgencyInfoParams,
): Promise<UpdateAgencyInfoResponse> =>
  appAxiosInstance[updateAgencyInfoApiMethod]<UpdateAgencyInfoResponse>(
    ApiPaths.AgencyPaths.updateAgencyInfoApi(params.lang),
    params,
  ).then((res) => res.data);
