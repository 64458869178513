import React, { FC, useState } from "react";
import {
  PersonalInfo,
  TransactionHistoryDetailsType,
} from "../../../../../../features/user/types";
import styles from "./HistoryItem.module.scss";
import Button from "../../../../../../components/Button/Button";
import cn from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTransactionStatusApi } from "../../../../../../features/user/api/updateTransactionStatus/updateTransactionStatus";
import { userQueries } from "../../../../../../features/user/api/queries";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";
import { TransactionStatuses } from "../../../../../../features/common/transactions/constants";

type HistoryItemProps = {
  personalInfo: PersonalInfo;
} & TransactionHistoryDetailsType;

const HistoryItem: FC<HistoryItemProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { t, i18n } = useTranslation();

  const { openModal, successModalElement } = useSuccessModal({
    text: "Status successfully changed",
  });

  const { mutate: updateStatus } = useMutation(updateTransactionStatusApi, {
    onSuccess: () => {
      queryClient?.invalidateQueries(
        userQueries.transactionHistory({
          lang: i18n.language,
        }).queryKey,
      );
      openModal();
    },
  });

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper__opened]: isOpen,
      })}
    >
      <div className={styles.header}>
        <span className={styles.gems}>
          {new Intl.NumberFormat("en-EN").format(props.gemsAmount)}{" "}
          {t("streamer.history.gemsWord")}
        </span>
        <div className={styles.header__common}>
          <span className={styles.common__date}>
            {format(new Date(props.createdAt), "dd MMMM yyyy")}
          </span>
          {isOpen ? (
            <Button
              onClick={() => setIsOpen(false)}
              className={cn(styles.btn, styles.close)}
            >
              {t("streamer.history.close")}
            </Button>
          ) : (
            <Button
              onClick={() => setIsOpen(true)}
              className={cn(styles.btn, styles.open)}
            >
              {t("streamer.history.open")}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.header__content}>
        <div className={styles.content__header}>
          <span className={styles.content__header__item}>
            {t("streamer.history.status")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("streamer.history.merchant")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("streamer.history.currency")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("streamer.history.payoutChannel")}
            <span className={styles.semi}>:</span>
          </span>
        </div>
        <div className={styles.content__row}>
          <div className={styles.content__row__item}>{props.status}</div>
          <div className={styles.content__row__item}>{props.agency}</div>
          <div className={styles.content__row__item}>{props.currency}</div>
          <div className={styles.content__row__item}>{props.payoutChannel}</div>
        </div>
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.content__info}>
            <div className={styles.info__column}>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("streamer.history.gemsTransferred")}
                </span>
                <span className={styles.info__item__content}>
                  {props.gemsAmount}
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("streamer.history.commission")}
                </span>
                <span className={styles.info__item__content}>
                  {props.commission}
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("streamer.history.fxRate")}
                </span>
                <span className={styles.info__item__content}>
                  {props.fxRate}
                </span>
              </div>
            </div>
            <div className={styles.info__column}>
              {props.agencyEmail && (
                <div className={styles.info__item}>
                  <span className={styles.info__item__title}>
                    {t("streamer.history.email")}
                  </span>
                  <span className={styles.info__item__content}>
                    {props.agencyEmail}
                  </span>
                </div>
              )}
              {props.agencyWhatsapp && (
                <div className={styles.info__item}>
                  <span className={styles.info__item__title}>
                    {t("streamer.history.whatsapp")}
                  </span>
                  <span className={styles.info__item__content}>
                    {props.agencyWhatsapp}
                  </span>
                </div>
              )}
              {props.paymentDetails && (
                <div className={styles.info__item}>
                  <span className={styles.info__item__title}>
                    {t("streamer.history.details")}
                  </span>
                  <span className={styles.info__item__content}>
                    {props.paymentDetails}
                  </span>
                </div>
              )}
            </div>
          </div>
          {props.status === TransactionStatuses.Sent && (
            <Button onClick={() => updateStatus({ id: props.id })}>
              {t("streamer.history.button")}
            </Button>
          )}
          {successModalElement}
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryItem);
