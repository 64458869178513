import React, { FC, useMemo } from "react";
import QueryFilter from "../QueryFilter/QueryFilter";
import { useTranslation } from "react-i18next";

type StatusType = {
  value: string;
  text: string;
};

type StatusFilterProps = {
  items?: StatusType[];
};

const StatusFilter: FC<StatusFilterProps> = ({ items }) => {
  const { t } = useTranslation();

  const statusData = useMemo(() => {
    if (items && items.length > 0) {
      return items;
    }

    return [
      {
        value: "GEMS_SENT",
        text: t("history.statusPending"),
      },
      {
        value: "PAYMENT_SENT",
        text: t("history.statusSent"),
      },
      {
        value: "COMPLETED",
        text: t("history.statusCompleted"),
      },
    ];
  }, [t, items]);

  return (
    <QueryFilter
      label={t("history.status")}
      emptyText={t("history.all")}
      queryName={"status"}
      items={statusData}
    />
  );
};

export default React.memo(StatusFilter);
