import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PersonalInfo } from "../../../../features/user/types";
import { AgencyType } from "../../../../features/agency/types";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { paymentQueries } from "../../../../features/payment/api/queries";
import SelectMenuItem from "../../../../components/SelectMenuItem/SelectMenuItem";
import { Select, SelectChangeEvent } from "@mui/material";
import { PagesRoutes } from "../../../../features/navigation/constants";
import PersonalErrorPlaceholder from "./components/PersonalErrorPlaceholder/PersonalErrorPlaceholder";
import cn from "classnames";
import FinalAmountBlock from "./components/FinalAmountBlock/FinalAmountBlock";
import styles from "./TransactionForm.module.scss";

type TransactionFormProps = {
  gemsBalance: number;
  userData: PersonalInfo;
  agencyData: AgencyType;
  userInfoIsComplete: boolean;
};

const TransactionForm: FC<TransactionFormProps> = ({
  gemsBalance,
  userData,
  agencyData,
  userInfoIsComplete,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedOptionIndex(event?.target?.value as number);
  };

  const { data: personalPayments } = useQuery(
    paymentQueries.personalList({
      lang: i18n.language,
    }),
  );

  const availablePaymentChannels = useMemo(() => {
    if (!personalPayments?.length || !agencyData.paymentChannels) {
      return null;
    }

    const available = personalPayments.filter((personal) =>
      agencyData.paymentChannels.some(
        (agency) => agency.id === personal.paymentChannel.id,
      ),
    );

    if (available.length > 0) {
      setSelectedOptionIndex(available[0].paymentChannel.id);
    }

    return available;
  }, [personalPayments, agencyData.paymentChannels]);

  const selectedPaymentChannel = useMemo(() => {
    return availablePaymentChannels?.find(
      (item) => item.paymentChannel.id === selectedOptionIndex,
    );
  }, [availablePaymentChannels, selectedOptionIndex]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        {!userInfoIsComplete && <PersonalErrorPlaceholder />}
        <div className={cn(styles.form__field, styles.streamer__balance)}>
          <span className={styles.field__title}>
            {t("transaction.balance")}
          </span>
          <span className={styles.field__content}>
            {gemsBalance} {t("transaction.gems")}
          </span>
        </div>
        <div className={styles.form__field}>
          <span className={styles.field__title}>
            {t("transaction.trovoUsername")}
          </span>
          <span className={styles.field__content}>
            {userData.trovoUsername}
          </span>
        </div>
        <div className={styles.form__field}>
          <span className={styles.field__title}>
            {t("transaction.fullName")}
          </span>
          <span className={styles.field__content}>{userData.fullName}</span>
        </div>
        {availablePaymentChannels && availablePaymentChannels.length > 0 ? (
          <div className={styles.form__field}>
            <span className={styles.field__title}>
              {t("transaction.selectChannel")}
            </span>
            <Select
              name="paymentChannel"
              classes={{
                select: styles.select,
                outlined: styles.outlined,
                icon: styles.icon,
                iconOpen: styles.iconOpen,
              }}
              onChange={handleSelectChange}
              defaultValue={
                availablePaymentChannels &&
                availablePaymentChannels[0].paymentChannel.id
              }
            >
              {availablePaymentChannels.map((channel, index) => {
                if (channel) {
                  return (
                    <SelectMenuItem
                      key={channel.id}
                      value={channel.paymentChannel.id}
                      classes={{
                        root: styles.select__item,
                      }}
                    >
                      {channel.paymentChannel.name}
                    </SelectMenuItem>
                  );
                }
                return null;
              })}
            </Select>
          </div>
        ) : (
          <div className={styles.form__field}>
            <p className={styles.field__title}>
              {t("transaction.addPaymentError")}{" "}
              <Link
                to={PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT_LIST}
                className={styles.field__content}
              >
                {t("transaction.addPaymentErrorLink")}
              </Link>
            </p>
          </div>
        )}

        {selectedPaymentChannel && (
          <>
            {selectedPaymentChannel.paymentChannel.column1Name && (
              <div className={styles.form__field}>
                <span className={styles.field__title}>
                  {selectedPaymentChannel.paymentChannel.column1Name}
                </span>
                <span className={styles.field__content}>
                  {selectedPaymentChannel.column1}
                </span>
              </div>
            )}
            {selectedPaymentChannel.paymentChannel.column2Name && (
              <div className={styles.form__field}>
                <span className={styles.field__title}>
                  {selectedPaymentChannel.paymentChannel.column2Name}
                </span>
                <span className={styles.field__content}>
                  {selectedPaymentChannel.column2}
                </span>
              </div>
            )}
            {selectedPaymentChannel.paymentChannel.column3Name && (
              <div className={styles.form__field}>
                <span className={styles.field__title}>
                  {selectedPaymentChannel.paymentChannel.column3Name}
                </span>
                <span className={styles.field__content}>
                  {selectedPaymentChannel.column3}
                </span>
              </div>
            )}
            {selectedPaymentChannel.paymentChannel.column4Name && (
              <div className={styles.form__field}>
                <span className={styles.field__title}>
                  {selectedPaymentChannel.paymentChannel.column4Name}
                </span>
                <span className={styles.field__content}>
                  {selectedPaymentChannel.column4}
                </span>
              </div>
            )}
            {selectedPaymentChannel.paymentChannel.column5Name && (
              <div className={styles.form__field}>
                <span className={styles.field__title}>
                  {selectedPaymentChannel.paymentChannel.column5Name}
                </span>
                <span className={styles.field__content}>
                  {selectedPaymentChannel.column5}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <FinalAmountBlock
        agencyData={agencyData}
        userData={userData}
        userInfoIsComplete={userInfoIsComplete}
        gemsBalance={gemsBalance}
        selectedOptionIndex={selectedOptionIndex}
        selectedPaymentChannel={selectedPaymentChannel}
      />
    </div>
  );
};

export default React.memo(TransactionForm);
