import React, { FC } from "react";
import styles from "./AgencyAnalytics.module.scss";
import AgencyDashboard from "../../AgencyDashboard";

type AgencyAnalyticsProps = {};

const AgencyAnalytics: FC<AgencyAnalyticsProps> = () => {
  return (
    <AgencyDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Analytics</h1>
        <div className={styles.content}>
          <p className={styles.text}>
            The page is currently under development. It will be available very
            soon
          </p>
        </div>
      </div>
    </AgencyDashboard>
  );
};

export default React.memo(AgencyAnalytics);
