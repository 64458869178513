import React, { FC } from "react";
import styles from "./CurrencyTableHeader.module.scss";
import { useTranslation } from "react-i18next";

type CurrencyTableHeaderProps = { selectedCurrency: string | null };

const CurrencyTableHeader: FC<CurrencyTableHeaderProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <span className={styles.text}>{t("agencies.agency")}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>USD / {props.selectedCurrency}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>{t("agencies.commission")}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>Platform Commission</span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>{t("agencies.channels")}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>{t("agencies.payment")}</span>
      </div>
    </div>
  );
};

export default React.memo(CurrencyTableHeader);
