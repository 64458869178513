import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { setupMockingServer } from "./utils/mockingServer/setupMockingServer";
import { Config } from "./constants/config";

import "./i18n";

setupMockingServer({
  enabled: Config.MOCK_ENABLE,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);
