import React, { FC } from "react";
import styles from "./EditPaymentModal.module.scss";
import { ModalProps } from "../../../../../../components/Modal/Modal.types";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { paymentQueries } from "../../../../../../features/payment/api/queries";
import { updatePersonalPaymentApi } from "../../../../../../features/payment/api/updatePersonalPayment/updatePersonalPayment";
import { Icons } from "../../../../../../constants/icons";
import { Field, Form, Formik, FormikHelpers } from "formik";
import Input from "../../../../../../components/Input/Input";
import Button from "../../../../../../components/Button/Button";
import { Modal } from "../../../../../../components/Modal/Modal";
import { PersonalPaymentType } from "../../../../../../features/payment/api/fetchPersonalPaymentList/types";
import { useErrorModal } from "../../../../../../components/modals/ErrorModal/useErrorModal";

type EditPaymentModalProps = {
  payment: PersonalPaymentType;
  openSuccessModal: () => void;
} & ModalProps;

type FormFields = {
  column1?: string;
  column2?: string;
  column3?: string;
  column4?: string;
  column5?: string;
};
const EditPaymentModal: FC<EditPaymentModalProps> = ({
  payment,
  openSuccessModal,
  ...restProps
}) => {
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { openModal: openErrorModal, errorModalElement } = useErrorModal({});

  const { mutate: updatePersonalPayment } = useMutation(
    updatePersonalPaymentApi,
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(
          paymentQueries.personalList({
            lang: i18n.language,
          }).queryKey,
        );
        restProps.onClose();
        openSuccessModal();
      },
      onError: (error) => {
        openErrorModal();
      },
    },
  );

  if (!payment.id) {
    return null;
  }

  return (
    <>
      {" "}
      <Modal
        containerClassName={styles.container}
        contentClassName={styles.content}
        {...restProps}
      >
        <header className={styles.header}>
          <h4 className={styles.title}>{payment.paymentChannel.name}</h4>
          <button onClick={restProps.onClose} className={styles.closeWrapper}>
            <Icons.Close className={styles.close} />
          </button>
        </header>
        <h3 className={styles.modalTitle}>
          {t("streamer.payments.item.editAccount")}
        </h3>
        <div className={styles.form}>
          <Formik
            initialValues={{
              column1: payment.column1 === null ? "" : payment.column1,
              column2: payment.column2 === null ? "" : payment.column2,
              column3: payment.column3 === null ? "" : payment.column3,
              column4: payment.column4 === null ? "" : payment.column4,
              column5: payment.column5 === null ? "" : payment.column5,
            }}
            onSubmit={(
              values,
              { setSubmitting }: FormikHelpers<FormFields>,
            ) => {
              updatePersonalPayment({
                id: payment.id,
                column1: values.column1 === "" ? null : values.column1,
                column2: values.column2 === "" ? null : values.column2,
                column3: values.column3 === "" ? null : values.column3,
                column4: values.column4 === "" ? null : values.column4,
                column5: values.column5 === "" ? null : values.column5,
              });
              setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <div className={styles.form__wrapper}>
                  {payment.paymentChannel.column1Name && (
                    <Field
                      name={"column1"}
                      as={Input}
                      labelText={payment.paymentChannel.column1Name}
                      className={styles.inp}
                    />
                  )}

                  {payment.paymentChannel.column2Name && (
                    <Field
                      name={"column2"}
                      as={Input}
                      labelText={payment.paymentChannel.column2Name}
                      className={styles.inp}
                    />
                  )}

                  {payment.paymentChannel.column3Name && (
                    <Field
                      name={"column3"}
                      as={Input}
                      labelText={payment.paymentChannel.column3Name}
                      className={styles.inp}
                    />
                  )}

                  {payment.paymentChannel.column4Name && (
                    <Field
                      name={"column4"}
                      as={Input}
                      labelText={payment.paymentChannel.column4Name}
                      className={styles.inp}
                    />
                  )}

                  {payment.paymentChannel.column5Name && (
                    <Field
                      name={"column5"}
                      as={Input}
                      labelText={payment.paymentChannel.column5Name}
                      className={styles.inp}
                    />
                  )}

                  <Button type={"submit"} className={styles.btn}>
                    {t("streamer.payments.submit")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <span className={styles.description}>
          {t("streamer.payments.item.description")}
        </span>
      </Modal>
      {errorModalElement}
    </>
  );
};

export default React.memo(EditPaymentModal);
