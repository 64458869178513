import React, { FC } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./LanguageToggle.module.scss";
import { Icons } from "../../constants/icons";
import Popover from "../Popover/Popover";

type LanguageToggleVariants = "black" | "white";

type LanguageToggleProps = {
  wrapperClassName?: string;
  buttonClassName?: string;
  variant?: LanguageToggleVariants;
};

const LanguageToggle: FC<LanguageToggleProps> = ({
  wrapperClassName,
  buttonClassName,
  variant = "black",
}) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const changeLanguage = (language: string) => {
    i18n?.changeLanguage(language);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={cn(styles.container, wrapperClassName)}>
      <button
        id={"language"}
        onClick={(event) => handleClick(event)}
        className={styles.iconWrapper}
      >
        <Icons.Earth
          className={cn(styles.icon, {
            [styles.whiteIcon]: variant === "white",
          })}
        />
      </button>
      <Popover
        id={"language"}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        modalClassName={styles.popover}
      >
        <div className={cn(styles.wrapper, styles[variant])}>
          <button
            className={cn(styles.lang, buttonClassName, {
              [styles.activeLang]: i18n.language === "en",
            })}
            onClick={() => changeLanguage("en")}
          >
            English
          </button>
          <button
            className={cn(styles.lang, buttonClassName, {
              [styles.activeLang]: i18n.language === "ar",
            })}
            onClick={() => changeLanguage("ar")}
          >
            عربي
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(LanguageToggle);
