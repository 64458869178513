import React, { FC } from "react";
import styles from "./TrovoButtonLink.module.scss";
import trovoLogoSmall from "../../../../assets/images/trovoLogoSmall.png";
import { useTranslation } from "react-i18next";

const TrovoButtonLink: FC = () => {
  const { t } = useTranslation();

  return (
    <a href={process.env.REACT_APP_AUTH_URL} className={styles.wrapper}>
      <div className={styles.icon__wrapper}>
        <img src={trovoLogoSmall} alt="Trovo" className={styles.icon} />
      </div>
      <span className={styles.text}>{t("auth.trovoButton")}</span>
    </a>
  );
};

export default React.memo(TrovoButtonLink);
