import React from "react";
import Skeleton from "../../components/Skeleton/Skeleton";
import styles from "./Transaction.module.scss";

const TransactionSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton variant={"rectangular"} className={styles.skeleton__header} />
      <div className={styles.main}>
        <Skeleton variant={"rectangular"} className={styles.skeleton__info} />
        <div className={styles.content}>
          <Skeleton
            variant={"rectangular"}
            className={styles.skeleton__formheader}
          />
          <Skeleton variant={"rectangular"} className={styles.skeleton__form} />
        </div>
      </div>
    </div>
  );
};

export default TransactionSkeleton;
