import React, { FC } from "react";
import styles from "./AdminHistory.module.scss";
import AdminDashboard from "../../AdminDashboard";
import HistoryList from "./components/HistoryList/HistoryList";
import { useTranslation } from "react-i18next";
import CurrencyQueryFilter from "../../../../components/CurrencyQueryFilter/CurrencyQueryFilter";
import StatusFilter from "../../../../components/StatusFilter/StatusFilter";
import { useQuery } from "@tanstack/react-query";
import { adminQueries } from "../../../../features/admin/api/queries";

type AdminHistoryProps = {};

const AdminHistory: FC<AdminHistoryProps> = () => {
  const { t } = useTranslation();

  const { data: statusList } = useQuery(adminQueries.statusList);

  return (
    <AdminDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("agency.history.title")}</h1>
        <div className={styles.filters}>
          <StatusFilter
            items={statusList?.map((item) => {
              return {
                value: item,
                text: item,
              };
            })}
          />
          <CurrencyQueryFilter />
        </div>
        <div className={styles.content}>
          <HistoryList />
        </div>
      </div>
    </AdminDashboard>
  );
};

export default React.memo(AdminHistory);
