import React, { FC } from "react";
import { MenuItem, MenuItemProps } from "@mui/material";
import styles from "./SelectMenuItem.module.scss";

type SelectMenuItemProps = {} & MenuItemProps;

const SelectMenuItem: FC<SelectMenuItemProps> = (props) => {
  return (
    <MenuItem
      classes={{ root: styles.root, selected: styles.selected }}
      {...props}
    />
  );
};

export default React.memo(SelectMenuItem);
