import React from "react";
import Header from "../../components/Header/Header";
import CurrencyFilter from "../../features/currency/components/CurrencyFilter/CurrencyFilter";
import CurrencyTable from "../../features/currency/components/CurrencyTable/CurrencyTable";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <div className={styles.wrapper}>
      <Header withBanner={true} />
      <div className={styles.content}>
        <CurrencyFilter />
        <CurrencyTable />
      </div>
    </div>
  );
};

export default Home;
