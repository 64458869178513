import React, { FC, useMemo } from "react";
import { Icons } from "../../constants/icons";
import styles from "./QueryFilter.module.scss";
import Popover from "../Popover/Popover";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";

type QueryFilterItemType = {
  value: string;
  text: string;
};

type QueryFilterProps = {
  emptyText: string;
  label: string;
  queryName: string;
  items: QueryFilterItemType[];
};

const QueryFilter: FC<QueryFilterProps> = ({
  items,
  queryName,
  label,
  emptyText,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const selectedValue = useMemo(() => {
    const value = searchParams.get(queryName);
    const name = items.find((elem) => elem.value === value);

    return name?.text ?? emptyText;
  }, [items, searchParams, queryName, emptyText]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (value: string) => {
    searchParams.set(queryName, value);
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  const handleClear = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    searchParams.delete(queryName);
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>
      <div id={emptyText} className={styles.input} onClick={handleClick}>
        <span className={styles.text}>{selectedValue}</span>
        {selectedValue !== emptyText ? (
          <button
            onClick={(event) => handleClear(event)}
            className={styles.closeWrapper}
          >
            <Icons.Close className={styles.close} />
          </button>
        ) : (
          <Icons.ArrowCircle
            className={cn(styles.icon, {
              [styles.icon__open]: !!anchorEl,
            })}
          />
        )}
      </div>
      <Popover
        id={emptyText}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        modalClassName={styles.popover}
      >
        <div className={styles.list}>
          {items.map((item, index) => {
            return (
              <button
                onClick={() => handleItemClick(item.value)}
                className={styles.item}
                key={index}
              >
                {item.text}
              </button>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(QueryFilter);
