import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TransactionAgencyInfo from "./components/TransactionAgencyInfo/TransactionAgencyInfo";
import TransactionForm from "./components/TransactionForm/TransactionForm";
import { useQuery } from "@tanstack/react-query";
import { userQueries } from "../../features/user/api/queries";
import { RedirectTo404Page } from "../common/RedirectTo404Page/RedirectTo404Page";
import { agencyQueries } from "../../features/agency/queries";
import { useTranslation } from "react-i18next";
import { PersonalInfo } from "../../features/user/types";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import TransactionSkeleton from "./Transaction.skeleton";
import styles from "./Transaction.module.scss";

const checkUserFields = (values: PersonalInfo) => {
  return Object.values(values).every((value) => {
    return Boolean(value);
  });
};

const Transaction = () => {
  const { t, i18n } = useTranslation();

  const { agencyId } = useParams<{ agencyId: string }>();

  const [searchParams] = useSearchParams();

  const selectedCurrency = useMemo(() => {
    return searchParams.get("currency");
  }, [searchParams]);

  const { data: balance, isLoading: balanceIsLoading } = useQuery(
    userQueries.balance,
  );

  const { data: personalInfo, isLoading: personalInfoIsLoading } = useQuery(
    userQueries.info({
      lang: i18n.language,
    }),
  );

  const { data: agencyData } = useQuery(
    agencyQueries.agency({
      id: agencyId ? agencyId : undefined,
      currency: selectedCurrency ? selectedCurrency : undefined,
      lang: i18n.language,
    }),
  );

  const userInfoIsComplete = useMemo(() => {
    if (!personalInfo) {
      return false;
    }

    return checkUserFields(personalInfo);
  }, [personalInfo]);

  if (!agencyId) {
    return <RedirectTo404Page />;
  }

  if (personalInfoIsLoading || balanceIsLoading || !personalInfo) {
    return <TransactionSkeleton />;
  }

  if (!agencyData || !selectedCurrency) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <DashboardHeader />
      <main className={styles.main}>
        <TransactionAgencyInfo {...agencyData} />
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>{t("transaction.title")}</h1>
            <span className={styles.note}>* 100 gems = 1 USD</span>
          </div>
          <TransactionForm
            gemsBalance={balance ?? 0}
            userData={personalInfo}
            agencyData={agencyData}
            userInfoIsComplete={userInfoIsComplete}
          />
        </div>
      </main>
    </div>
  );
};

export default Transaction;
