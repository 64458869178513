import React, { FC } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { userQueries } from "../../../../../../features/user/api/queries";
import { updatePersonalInfoApi } from "../../../../../../features/user/api/updatePersonalInfo/updatePersonalInfo";
import Loader from "../../../../../../components/Loader/Loader";
import { PersonalInfo } from "../../../../../../features/user/types";
import Input from "../../../../../../components/Input/Input";
import Button from "../../../../../../components/Button/Button";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";
import styles from "./StreamerPersonalForm.module.scss";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  phone: Yup.string().required("Phone is required"),
  whatsapp: Yup.string().required("Whatsapp or Telegram is required"),
});

type FormFieldType = Omit<PersonalInfo, "email">;

type StreamerPersonalFormProps = {};

const StreamerPersonalForm: FC<StreamerPersonalFormProps> = () => {
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    userQueries.info({
      lang: i18n.language,
    }),
  );

  const { openModal, successModalElement } = useSuccessModal({
    text: "Information successfully saved",
  });

  const { mutate: updatePersonalInfo } = useMutation(updatePersonalInfoApi, {
    onSuccess: () => {
      queryClient?.invalidateQueries(
        userQueries.info({
          lang: i18n.language,
        }).queryKey,
      );
      openModal();
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          id: data.id,
          fullName: data.fullName,
          whatsapp: data.whatsapp,
          city: data.city,
          country: data.country,
          trovoUsername: data.trovoUsername,
          phone: data.phone,
          profilePic: data.profilePic,
        }}
        validationSchema={validationSchema}
        onSubmit={(
          values: FormFieldType,
          { setSubmitting }: FormikHelpers<FormFieldType>,
        ) => {
          updatePersonalInfo({
            fullName: values.fullName,
            phone: values.phone,
            profilePic: values.profilePic,
            country: values.country,
            city: values.city,
            whatsapp: values.whatsapp,
          });
        }}
      >
        {({ errors }: FormikProps<FormFieldType>) => (
          <Form>
            <div className={styles.wrapper}>
              <Field
                name="fullName"
                as={Input}
                labelText={t("streamer.personal.fullName")}
                wrapperClassName={styles.inp}
                editable={true}
                disabled={true}
                requiredText={true}
                error={errors.fullName}
              />
              <Field
                name="trovoUsername"
                as={Input}
                labelText={t("streamer.personal.trovoUsername")}
                wrapperClassName={styles.inp}
                disabled={true}
              />
              <Field
                name="phone"
                as={Input}
                labelText={t("streamer.personal.phone")}
                wrapperClassName={styles.inp}
                disabled={true}
                editable={true}
                requiredText={true}
                error={errors.phone}
              />
              <Field
                name="country"
                as={Input}
                labelText={t("streamer.personal.country")}
                wrapperClassName={styles.inp}
                editable={true}
                disabled={true}
                requiredText={true}
                error={errors.country}
              />
              <Field
                name="city"
                as={Input}
                labelText={t("streamer.personal.city")}
                wrapperClassName={styles.inp}
                disabled={true}
                editable={true}
                requiredText={true}
                error={errors.city}
              />
              <Field
                name="whatsapp"
                as={Input}
                labelText={t("streamer.personal.whatsapp")}
                wrapperClassName={styles.inp}
                disabled={true}
                editable={true}
                requiredText={true}
                error={errors.whatsapp}
              />
            </div>
            <Button type="submit" className={styles.btn}>
              {t("streamer.personal.save")}
            </Button>
          </Form>
        )}
      </Formik>
      {successModalElement}
    </>
  );
};

export default React.memo(StreamerPersonalForm);
