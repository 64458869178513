import { PersonalInfo } from "../../types";
import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchPersonalInfoApiParams,
  FetchPersonalInfoApiResponse,
} from "./types";

export const fetchPersonalInfoApiMethod = ApiMethods.GET;

export const fetchPersonalInfoApi = (
  params: FetchPersonalInfoApiParams,
): Promise<PersonalInfo> =>
  appAxiosInstance[fetchPersonalInfoApiMethod]<FetchPersonalInfoApiResponse>(
    ApiPaths.UserPaths.fetchPersonalInfoApi,
    {
      params,
    },
  )
    .then((res) => res.data)
    .then((body) => {
      return body;
    });
