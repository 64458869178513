import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { UpdatePersonalInfoParams, UpdatePersonalInfoResponse } from "./types";

export const updatePersonalInfoApiMethod = ApiMethods.PUT;

export const updatePersonalInfoApi = (
  params: UpdatePersonalInfoParams,
): Promise<UpdatePersonalInfoResponse> =>
  appAxiosInstance[updatePersonalInfoApiMethod]<UpdatePersonalInfoResponse>(
    ApiPaths.UserPaths.updatePersonalInfoApi,
    params,
  ).then((res) => res.data);
