import { useModal } from "../../Modal/useModal";
import ErrorModal from "./ErrorModal";

type UseErrorModalProps = {
  errorText?: string;
};

export const useErrorModal = ({ errorText }: UseErrorModalProps) => {
  const { open, openModal, closeModal } = useModal();

  const errorModalElement = (
    <ErrorModal errorText={errorText} open={open} onClose={closeModal} />
  );

  return {
    open,
    openModal,
    closeModal,
    errorModalElement,
  };
};
