import React, { FC } from "react";
import Header from "../../../components/Header/Header";
import styles from "./PageUnderDevelopment.module.scss";

type PageUnderDevelopmentProps = {};

const PageUnderDevelopment: FC<PageUnderDevelopmentProps> = () => {
  return (
    <div className={styles.wrapper}>
      <Header withBanner={false} />
      <div className={styles.content}>
        <p className={styles.text}>
          The page is currently under development. It will be available very
          soon
        </p>
      </div>
    </div>
  );
};

export default React.memo(PageUnderDevelopment);
