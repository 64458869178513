import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchCurrenciesApiResponse } from "./types";

export const fetchCurrenciesApiMethod = ApiMethods.GET;

export const fetchCurrenciesApi = (): Promise<FetchCurrenciesApiResponse> =>
  appAxiosInstance[fetchCurrenciesApiMethod]<FetchCurrenciesApiResponse>(
    ApiPaths.CurrencyPaths.fetchCurrenciesApi,
  ).then((res) => res.data);
