import styles from "./Terms.module.scss";
import Header from "../../components/Header/Header";

const Terms = () => {
  return (
    <div className={styles.wrapper}>
      <Header withBanner={false} />
      <div className={styles.content}>
        <h1>Aqua Creative Terms of Program Payments</h1>
        <p>
          These Terms of Program Payments (“Agreement”) are a binding legal
          agreement between you and Aqua Creative (Global) Limited, registration
          number 3203631, registration address Room 1605, Ho King Commercial
          Centre, 2-16 Fa Yuen Street, Mongkok, Kowloon, Hong Kong (“Aqua
          Creative”, “we,” “us” or “our”), and govern your access to participate
          in the Aqua Creative and Trovo programs (“Program”) and receive
          payments.
        </p>
        <p>
          Any person that participates or attempts to participate in the Program
          must accept this Agreement without change. By registering for the
          Program, you have agreed to the terms and conditions of this
          Agreement, and have entered into a legal contract between you and Aqua
          Creative; provided, however, that this Agreement shall not be
          effective until we determine that you are eligible to participate in
          the Program.
        </p>
        <ul>
          <span>
            The Trovo Terms of Service separately govern your use of the Trovo’s
            live streaming platform.{" "}
          </span>
          <li>
            1. Description of the Program. The Program permits you to monetize
            the broadcasting, streaming. The Program is the activity of the
            participant associated with the implementation of special actions,
            as a result of which the participant can receive a monetary reward.
            Information about programs, terms of their validity, reward size is
            posted on the official websites: https://trovo.live/
          </li>
          <li>
            2. Compliance Requirements. As part of the enrollment process into
            the Program, you must submit a complete and accurate data for
            participate (including all forms, documents, or certifications as
            may be required to satisfy any tax obligations with respect to
            payments under this Agreement).{" "}
          </li>
          <li>
            <ul>
              <li>
                2.1. You will ensure that the information in your Program
                application and otherwise associated with your account,
                including your email address and other contact information and
                identification of your Trovo channel, is at all times complete,
                accurate, and up-to-date.
              </li>
              <li>
                2.2. You must promptly provide us with information that we
                request to verify your compliance with this Agreement.
              </li>
            </ul>
          </li>
          <li>
            3. Payment Terms. Payment terms, payment amount, payment currency,
            payment procedure are determined according to the Program and
            options in the personal account. To ensure proper payment, you are
            responsible for providing and maintaining accurate contact and
            payment information through the Trovo.
          </li>
          <li>
            4.Taxes. The participant of the program undertakes to independently
            fulfill all necessary tax obligations. We may deduct or withhold any
            taxes that we may be legally obligated to deduct or withhold from
            any payment made to you under this Agreement, and payment to you as
            reduced by such deductions or withholdings will constitute full
            payment and settlement to you of such payment under this Agreement.
          </li>
          <li>
            5. Data Privacy. We are respects your privacy and we acknowledge
            that you have certain rights related to any personal information we
            collect from you and we have certain obligations in respect of the
            same. Aqua Creative collects, stores, and uses personal data to
            provide Program payments. When you agree to the Agreement, also you
            should agree our Privacy Policy or we will not be able to make the
            payments.
          </li>
          <li>
            6. Representations and Warranties. You represent, warrant, and
            covenant that (a) you are of legal age and have not been previously
            removed from or prohibited from receiving the Trovo, (b) you will
            participate in the Program in accordance with this Agreement and
            Privacy Policy, (c) your participation in the Program will not
            violate any applicable laws, ordinances, rules, regulations, orders,
            licenses, permits, industry standards, judgments, decisions, or
            other requirements of any applicable governmental authority
            (including all such rules governing communications and marketing),
            (d) you have the full right, power and authority to enter into this
            Agreement and to perform the acts required hereunder; (e) your
            execution of this Agreement, and your performance of your
            obligations and duties hereunder, do not and will not violate any
            agreement to which you are a party or by which you are otherwise
            bound; and (f) the information you provide in connection with the
            Program is accurate and complete at all times.
          </li>
          <span>
            We do not make any representation, warranty, or covenant regarding
            the amount of Program Fees you can expect at any time in connection
            with the Program, and we will not be liable for any actions you
            undertake based on your expectations.
          </span>
          <li>
            7. Term and Termination. The term of this Agreement will begin upon
            our acceptance of your Program application and will end when
            terminated by either you or us
          </li>
          <li>
            8. Indemnification. You hereby release and agree to defend, hold
            harmless, and indemnify us, and/or our subsidiaries, affiliates,
            directors, officers, employees, agents, successors and assigns from
            and against any allegation or claim based on, or any loss, damage,
            settlement, cost, expense and any other liability (including but not
            limited to reasonable attorneys’ fees incurred and/or those
            necessary to successfully establish the right to indemnification),
            arising from or related to: (a) any act or omission by you,
            including, without limitation any breach of this Agreement or
            allegation or claim of negligence, strict liability, willful
            misconduct or fraud of you; or (b) your access to or use of the
            Program.
          </li>
          <li>
            9. Limitation of Liability. NEITHER WE NOR ANY OF OUR AFFILIATES OR
            LICENSORS WILL BE LIABLE TO YOU FOR LOST REVENUE, LOST PROFITS, LOST
            BUSINESS, OR INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
            EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES) ARISING FROM OR RELATING TO THIS AGREEMENT OR THE
            PROGRAM. FURTHER, OUR AGGREGATE LIABILITY TO YOU, UNDER ANY THEORY
            OF LIABILITY, IN CONNECTION WITH THIS AGREEMENT AND THE PROGRAM WILL
            NOT EXCEED THE FEES PAID OR PAYABLE TO YOU UNDER THIS AGREEMENT IN
            THE TWELVE MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE EVENT
            GIVING RISE TO THE MOST RECENT CLAIM OF LIABILITY OCCURRED.
          </li>
          <li>
            10. Relationship of Parties. You and we are, and will remain at all
            times, independent contractors, and nothing in this Agreement will
            be construed to create an agency, employment, fiduciary,
            representative or any other relationship between you and us. You
            will not represent yourself to be an employee, representative, or
            agent of us. You understand and agree that you do not have authority
            to bind us in any manner, or enter into any agreement or incur any
            liability on behalf of us.
          </li>
          <li>
            11. Governing Law, Jurisdiction. This Agreement is governed in
            accordance with the laws of Hong Kong SAR.
          </li>
          <li>
            12. Entire Agreement. This Agreement contains the complete
            understanding between the parties with respect to their respective
            subject matter hereof and supersedes and cancels all previous
            written and oral agreements, communications and other understandings
            relating to the subject matter of this Agreement, and will not be
            modified except in writing, signed or otherwise agreed to by both
            parties.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Terms;
