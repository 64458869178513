import React, { FC } from "react";
import styles from "./BatchItem.module.scss";
import { BatchTransactionType } from "../../../../../../features/admin/types";
import Button from "../../../../../../components/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendBatchTransactionApi } from "../../../../../../features/admin/api/sendBatchTransaction/sendBatchTransaction";
import { adminQueries } from "../../../../../../features/admin/api/queries";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";

type BatchItemProps = {} & BatchTransactionType;

const BatchItem: FC<BatchItemProps> = ({
  id,
  totalGems,
  status,
  agencyName,
  createdAt,
  currency,
  binanceId,
  binanceEmail,
}) => {
  const queryClient = useQueryClient();

  const { mutate: sendBatchTransaction } = useMutation(
    sendBatchTransactionApi,
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(adminQueries.batchTransactions);
        openModal();
      },
    },
  );

  const handleSendButtonClick = () => {
    sendBatchTransaction({
      id: id,
    });
  };

  const { openModal, successModalElement } = useSuccessModal({
    text: "Success",
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.row}>
          <span className={styles.row__title}>ID: </span>
          <span className={styles.row__content}>{id}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.row__title}>Agency Name: </span>
          <span className={styles.row__content}>{agencyName}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.row__title}>Approved amount: </span>
          <span className={styles.row__content}>{totalGems}</span>
        </div>
        {currency && (
          <div className={styles.row}>
            <span className={styles.row__title}>Currency: </span>
            <span className={styles.row__content}>{currency}</span>
          </div>
        )}
        <div className={styles.row}>
          <span className={styles.row__title}>Status: </span>
          <span className={styles.row__content}>{status}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.row__title}>Binance Id: </span>
          <span className={styles.row__content}>{binanceId}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.row__title}>Binance Email: </span>
          <span className={styles.row__content}>{binanceEmail}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.row__title}>Created At: </span>
          <span className={styles.row__content}>{createdAt}</span>
        </div>
      </div>
      {status === "INITIAL" && (
        <Button
          onClick={() => handleSendButtonClick()}
          variant={"secondary"}
          className={styles.btn}
        >
          {currency === "RUB" ? "Send RUB" : "Send USDT"}
        </Button>
      )}
      {successModalElement}
    </div>
  );
};

export default React.memo(BatchItem);
