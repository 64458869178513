import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchCurrencyAgencyApiParams,
  FetchCurrencyAgencyApiResponse,
} from "./types";
import { AgencyType } from "../../../agency/types";

export const fetchCurrencyAgencyApiMethod = ApiMethods.GET;

export const fetchCurrencyAgencyApi = (
  params: FetchCurrencyAgencyApiParams,
): Promise<AgencyType[]> =>
  appAxiosInstance[
    fetchCurrencyAgencyApiMethod
  ]<FetchCurrencyAgencyApiResponse>(
    ApiPaths.CurrencyPaths.fetchCurrencyAgencyApi,
    { params },
  ).then((res) => res.data);
