import React from "react";
import { useQuery } from "@tanstack/react-query";
import { agencyQueries } from "../../../agency/queries";
import RootWithPageLayout from "../../../../pages/common/RootWithPageLayout/RootWithPageLayout";
import Loader from "../../../../components/Loader/Loader";
import { Navigate } from "react-router-dom";
import { PagesRoutes } from "../../constants";
import { useTranslation } from "react-i18next";

const AgencyRoutes = () => {
  const { i18n } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    agencyQueries.agencyInfo({
      lang: i18n.language,
    }),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!data || isError) {
    return <Navigate to={PagesRoutes.COMMON.MAIN} />;
  }

  return <RootWithPageLayout />;
};

export default React.memo(AgencyRoutes);
