import { createQueryKeys } from "@lukemorales/query-key-factory";
import { fetchCurrencyAgencyApi } from "./fetchCurrencyAgency/fetchCurrencyAgency";
import { FetchCurrencyAgencyApiParams } from "./fetchCurrencyAgency/types";
import { fetchCurrenciesApi } from "./fetchCurrencies/fetchCurrencies";

export const currencyQueries = createQueryKeys("currency", {
  list: (params: FetchCurrencyAgencyApiParams) => ({
    queryKey: [params],
    queryFn: () =>
      fetchCurrencyAgencyApi({ currency: params.currency, lang: params.lang }),
  }),
  currencies: {
    queryKey: null,
    queryFn: () => fetchCurrenciesApi(),
  },
});
