import React, { FC } from "react";
import QueryFilter from "../QueryFilter/QueryFilter";

type BatchTimeSortProps = {};

const filterData = [
  {
    text: "From earliest to oldest",
    value: "EARLIEST",
  },
  {
    text: "From oldest to earliest",
    value: "OLDEST",
  },
];

const TimeSort: FC<BatchTimeSortProps> = () => {
  return (
    <QueryFilter
      label={"Order By"}
      emptyText={"All"}
      items={filterData}
      queryName={"sort"}
    />
  );
};

export default React.memo(TimeSort);
