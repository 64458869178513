import React, { FC } from "react";
import styles from "./ErrorModal.module.scss";
import { ModalProps } from "../../Modal/Modal.types";
import { Modal } from "../../Modal/Modal";
import { Icons } from "../../../constants/icons";
import Button from "../../Button/Button";

type ErrorModalProps = {
  errorText?: string;
} & Pick<ModalProps, "open" | "onClose">;

const ErrorModal: FC<ErrorModalProps> = ({ errorText, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <Icons.Error className={styles.icon} />
        </div>
        <span className={styles.text}>
          {errorText ?? "Something went wrong..."}
        </span>
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};

export default React.memo(ErrorModal);
