import React, { FC } from "react";
import AgencyDashboard from "../../AgencyDashboard";
import styles from "./CurrencyDetails.module.scss";
import { useQuery } from "@tanstack/react-query";
import { agencyQueries } from "../../../../features/agency/queries";
import Loader from "../../../../components/Loader/Loader";
import CurrencyDetailsItem from "./components/CurrencyDetailsItem/CurrencyDetailsItem";
import { useTranslation } from "react-i18next";

type CurrencyDetailsProps = {};

const CurrencyDetails: FC<CurrencyDetailsProps> = () => {
  const { t, i18n } = useTranslation();

  const { data: currencyDetails, isLoading } = useQuery(
    agencyQueries.currencyDetails({
      lang: i18n.language,
    }),
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AgencyDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("agency.currency.title")}</h1>
        <div className={styles.list}>
          {currencyDetails &&
            currencyDetails.map((item) => {
              return (
                <CurrencyDetailsItem
                  id={item.id}
                  currency={item.currency}
                  paymentDetails={item.paymentDetails}
                  fxRate={item.fxRate}
                  commission={item.commission}
                  paymentChannels={item.paymentChannels}
                  key={item.id}
                />
              );
            })}
        </div>
      </div>
    </AgencyDashboard>
  );
};

export default React.memo(CurrencyDetails);
