import { createQueryKeys } from "@lukemorales/query-key-factory";
import { fetchPaymentListApi } from "./fetchPaymentList/fetchPaymentList";
import { FetchPaymentApiParams } from "./fetchPayment/types";
import { fetchPaymentApi } from "./fetchPayment/fetchPayment";
import { fetchPersonalPaymentListApi } from "./fetchPersonalPaymentList/fetchPersonalPaymentList";
import { FetchPaymentListApiParams } from "./fetchPaymentList/types";
import { FetchPersonalPaymentListParams } from "./fetchPersonalPaymentList/types";

export const paymentQueries = createQueryKeys("payment", {
  list: (params: FetchPaymentListApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchPaymentListApi(params),
    };
  },
  payment: (params: FetchPaymentApiParams) => {
    const { id, lang } = params;

    return {
      queryKey: [id ?? {}],
      queryFn: () => (!!id ? fetchPaymentApi({ id, lang }) : undefined),
    };
  },
  personalList: (params: FetchPersonalPaymentListParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchPersonalPaymentListApi(params),
    };
  },
});
