import React from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ScrollRestoration } from "../ScrollRestoration/ScrollRestoration";
import { PagesRoutes } from "../../constants";
import RootWithPageLayout from "../../../../pages/common/RootWithPageLayout/RootWithPageLayout";
import { RedirectTo404Page } from "../../../../pages/common/RedirectTo404Page/RedirectTo404Page";
import StreamerRoutes from "../StreamerRoutes/StreamerRoutes";
import AgencyRoutes from "../AgencyRoutes/AgencyRoutes";
import AdminRoutes from "../AdminRoutes/AdminRoutes";

export const RouterContextCommonConsumers = () => {
  return (
    <>
      <ScrollRestoration />

      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RouterContextCommonConsumers />}>
      {Object.values(PagesRoutes.COMMON).map((path) => {
        return (
          <Route
            key={"withPageLayout"}
            path={path}
            element={<RootWithPageLayout />}
          />
        );
      })}
      {Object.values(PagesRoutes.STREAMER).map((path) => {
        return (
          <Route
            key={"streamerRoutes"}
            path={path}
            element={<StreamerRoutes />}
          />
        );
      })}
      {Object.values(PagesRoutes.AGENCY).map((path) => {
        return (
          <Route key={"agencyRoutes"} path={path} element={<AgencyRoutes />} />
        );
      })}
      {Object.values(PagesRoutes.ADMIN).map((path) => {
        return (
          <Route key={"adminRoutes"} path={path} element={<AdminRoutes />} />
        );
      })}
      <Route path="*" element={<RedirectTo404Page />} />
    </Route>,
  ),
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
