import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchAdminTransactionStatusListResponse } from "./types";

export const fetchAdminTransactionStatusListApiMethod = ApiMethods.GET;

export const fetchAdminTransactionStatusListApi =
  (): Promise<FetchAdminTransactionStatusListResponse> =>
    appAxiosInstance[fetchAdminTransactionStatusListApiMethod](
      ApiPaths.AdminPaths.fetchAdminTransactionStatusListApi,
    ).then((res) => res.data);
