import React, { FC } from "react";
import { PersonalPaymentType } from "../../../../../../../../features/payment/api/fetchPersonalPaymentList/types";
import Button from "../../../../../../../../components/Button/Button";
import cn from "classnames";
import EditPaymentModal from "../../../EditPaymentModal/EditPaymentModal";
import { useModal } from "../../../../../../../../components/Modal/useModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { paymentQueries } from "../../../../../../../../features/payment/api/queries";
import { useTranslation } from "react-i18next";
import { useSuccessModal } from "../../../../../../../../components/modals/SuccessModal/useSuccessModal";
import { deletePaymentChannelApi } from "../../../../../../../../features/user/api/deletePaymentChannel/deletePaymentChannel";
import styles from "./StreamerPersonalPaymentsItem.module.scss";

type StreamerPersonalPaymentsItemProps = {} & PersonalPaymentType;

const StreamerPersonalPaymentsItem: FC<StreamerPersonalPaymentsItemProps> = (
  props,
) => {
  const { open, openModal, closeModal } = useModal();
  const { openModal: openSuccessModal, successModalElement } = useSuccessModal({
    text: "Information successfully saved",
  });

  const {
    openModal: openDeleteSuccessModal,
    successModalElement: deleteSuccessModalElement,
  } = useSuccessModal({
    text: "Payment channel was deleted",
  });

  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate: deletePersonalPayment } = useMutation(
    deletePaymentChannelApi,
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(
          paymentQueries.personalList({
            lang: i18n.language,
          }).queryKey,
        );
        openDeleteSuccessModal();
      },
    },
  );

  const handleRemovePaymentChannel = () => {
    // todo confirm modal???
    deletePersonalPayment({
      id: props.id,
    });
  };

  return (
    <>
      <div className={styles.item}>
        <div className={styles.item__header}>
          <h5 className={styles.item__title}>{props.paymentChannel.name}</h5>
          <div className={styles.item__btns}>
            <Button
              size={"small"}
              onClick={openModal}
              className={cn(styles.btn, styles.btn__edit)}
            >
              {t("streamer.payments.item.editBtn")}
            </Button>
            <Button
              size={"small"}
              onClick={handleRemovePaymentChannel}
              className={cn(styles.btn, styles.btn__remove)}
            >
              {t("streamer.payments.item.removeBtn")}
            </Button>
          </div>
        </div>
        <div className={styles.item__wrapper}>
          {props.paymentChannel.column1Name && (
            <div className={styles.item__content}>
              <span className={styles.item__content__title}>
                {props.paymentChannel.column1Name}
              </span>
              <span className={styles.item__content__text}>
                {props.column1}
              </span>
            </div>
          )}

          {props.paymentChannel.column2Name && (
            <div className={styles.item__content}>
              <span className={styles.item__content__title}>
                {props.paymentChannel.column2Name}
              </span>
              <span className={styles.item__content__text}>
                {props.column2}
              </span>
            </div>
          )}

          {props.paymentChannel.column3Name && (
            <div className={styles.item__content}>
              <span className={styles.item__content__title}>
                {props.paymentChannel.column3Name}
              </span>
              <span className={styles.item__content__text}>
                {props.column3}
              </span>
            </div>
          )}

          {props.paymentChannel.column4Name && (
            <div className={styles.item__content}>
              <span className={styles.item__content__title}>
                {props.paymentChannel.column4Name}
              </span>
              <span className={styles.item__content__text}>
                {props.column4}
              </span>
            </div>
          )}

          {props.paymentChannel.column5Name && (
            <div className={styles.item__content}>
              <span className={styles.item__content__title}>
                {props.paymentChannel.column5Name}
              </span>
              <span className={styles.item__content__text}>
                {props.column5}
              </span>
            </div>
          )}
        </div>
      </div>
      <EditPaymentModal
        open={open}
        onClose={closeModal}
        openSuccessModal={openSuccessModal}
        payment={props}
      />
      {successModalElement}
      {deleteSuccessModalElement}
    </>
  );
};

export default React.memo(StreamerPersonalPaymentsItem);
