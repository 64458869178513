import React, { FC, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PagesRoutes } from "../../features/navigation/constants";
import styles from "./MobileMenu.module.scss";
import cn from "classnames";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logoutUserApi } from "../../features/user/api/logoutUser/logoutUser";
import { agencyQueries } from "../../features/agency/queries";

type MobileMenuProps = {
  isOpen: boolean;
  handleCloseMobileMenu: () => void;
};

const MobileMenu: FC<MobileMenuProps> = ({ isOpen, handleCloseMobileMenu }) => {
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  useEffect(() => {
    isOpen && (document.body.style.overflow = "hidden");
    !isOpen && (document.body.style.overflow = "unset");
  }, [isOpen]);

  useEffect(() => {
    handleCloseMobileMenu();
  }, [i18n.language, handleCloseMobileMenu]);

  const { mutate: logout } = useMutation(logoutUserApi, {
    onSettled: () => {
      queryClient?.invalidateQueries(
        agencyQueries.agencyInfo({ lang: i18n.language }).queryKey,
      );
    },
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(PagesRoutes.COMMON.MAIN, { replace: true });
    window.location.reload();
  };

  return (
    <div
      className={cn(styles.mobileMenu, {
        [styles.isOpen]: isOpen,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.mobileMenuWrapper}>
          <nav className={styles.menu}>
            <LanguageToggle
              variant={"white"}
              wrapperClassName={styles.languageToggle}
            />
            <Link to={PagesRoutes.COMMON.MAIN} className={styles.link}>
              {t("header.payment")}
            </Link>
            <button onClick={handleLogout} className={styles.logout}>
              {t("header.logout")}
            </button>
          </nav>
          <div className={styles.footer}>
            <a href="mailto:info@creator-go.com" className={styles.mailLink}>
              <span>Email:</span> info@creator-go.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MobileMenu);
