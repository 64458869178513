import { Popover as PopoverMui } from "@mui/material";
import { PopoverProps as PopoverPropsMui } from "@mui/material/Popover/Popover";
import styles from "./Popover.module.scss";
import cn from "classnames";

const defaultAnchorOrigin = {
  vertical: "bottom",
  horizontal: "center",
} as const;

const defaultTransformOrigin = {
  vertical: "top",
  horizontal: "center",
} as const;

export type PopoverProps = {
  modalClassName?: string;
} & Pick<
  PopoverPropsMui,
  | "id"
  | "open"
  | "anchorEl"
  | "children"
  | "onClose"
  | "disableScrollLock"
  | "anchorOrigin"
  | "transformOrigin"
>;
const Popover = (props: PopoverProps) => {
  const {
    modalClassName,
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
    ...restProps
  } = props;

  return (
    <PopoverMui
      classes={{ paper: cn(styles.modal, modalClassName) }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...restProps}
    />
  );
};

export default Popover;
