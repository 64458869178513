import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  UpdateCurrencyDetailApiParams,
  UpdateCurrencyDetailApiResponse,
} from "./types";

export const updateCurrencyDetailApiMethod = ApiMethods.POST;

export const updateCurrencyDetailApi = (
  params: UpdateCurrencyDetailApiParams,
): Promise<UpdateCurrencyDetailApiResponse> =>
  appAxiosInstance[
    updateCurrencyDetailApiMethod
  ]<UpdateCurrencyDetailApiResponse>(
    ApiPaths.AgencyPaths.updateAgencyCurrencyDetailApi(params.lang),
    params,
  ).then((res) => res.data);
