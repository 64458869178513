import React, { FC, useMemo, useState } from "react";
import styles from "./FinalAmountBlock.module.scss";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../components/Button/Button";
import { AgencyType } from "../../../../../../features/agency/types";
import { useMutation } from "@tanstack/react-query";
import { createTransactionApi } from "../../../../../../features/transaction/api/createTransaction/createTransaction";
import { useSearchParams } from "react-router-dom";
import VerifyModal from "../../../VerifyModal/VerifyModal";
import { RedirectTo404Page } from "../../../../../common/RedirectTo404Page/RedirectTo404Page";
import { PersonalPaymentType } from "../../../../../../features/payment/api/fetchPersonalPaymentList/types";
import { useModal } from "../../../../../../components/Modal/useModal";
import { PersonalInfo } from "../../../../../../features/user/types";
import PaymentDeclineModal from "../../../PaymentDeclineModal/PaymentDeclineModal";

type FinalAmountBlockProps = {
  gemsBalance: number;
  userData: PersonalInfo;
  agencyData: AgencyType;
  selectedPaymentChannel: PersonalPaymentType | undefined;
  userInfoIsComplete: boolean;
  selectedOptionIndex: number;
};

const FinalAmountBlock: FC<FinalAmountBlockProps> = ({
  gemsBalance,
  agencyData,
  userData,
  selectedPaymentChannel,
  userInfoIsComplete,
  selectedOptionIndex,
}) => {
  const { t } = useTranslation();
  const { open, openModal, closeModal } = useModal();

  const {
    open: declineModalIsOpen,
    openModal: handleOpenDeclineModal,
    closeModal: handleCloseDeclineModal,
  } = useModal();

  const [validationError, setValidationError] = useState<string | null>(null);
  const [gemsAmount, setGemsAmount] = useState<string>("1300");

  const [searchParams] = useSearchParams();

  const selectedCurrency = useMemo(
    () => searchParams.get("currency"),
    [searchParams],
  );

  const { mutate: createTransaction, data: createTransactionResponse } =
    useMutation(createTransactionApi, {
      onSuccess: () => {
        openModal();
      },
      onError: () => {
        handleOpenDeclineModal();
      },
    });

  const isCreativePro = agencyData.name === "Pro Aqua Creative";
  const selectedCurrencyIsRub = selectedCurrency === "RUB";

  const handleChangeGemInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setGemsAmount(inputValue);

    if (inputValue === "") {
      setValidationError("Gems amount is required");
    } else if (isNaN(Number(inputValue)) || Number(inputValue) < 1300) {
      setValidationError(
        "Gems amount must be a number and greater than or equal to 1300",
      );
    } else if (Number(inputValue) > gemsBalance) {
      setValidationError("Gems amount exceeds your balance");
    } else {
      setValidationError(null);
    }
  };

  const validGemsAmount = useMemo(() => {
    return +gemsAmount <= +gemsBalance && +gemsAmount >= 1300;
  }, [gemsAmount, gemsBalance]);

  const finalAmountInUSDT = useMemo(() => {
    const numberCommission = +agencyData.commission.slice(0, -1);
    const platformCommission = +agencyData.aquaCommission.slice(0, -1);
    const finalCommission = numberCommission + platformCommission;
    const result = (+gemsAmount / 100) * (1 - finalCommission / 100);

    if (isCreativePro && !selectedCurrencyIsRub) {
      return result - 1;
    }

    return result;
  }, [
    gemsAmount,
    agencyData.commission,
    isCreativePro,
    agencyData.aquaCommission,
    selectedCurrencyIsRub,
  ]);

  const finalAmountInCurrency = useMemo(() => {
    if (
      !agencyData.exchangeRate ||
      !Boolean(agencyData.exchangeRate.match(/^\d/))
    ) {
      return null;
    }

    const [firstPart, secondPart] = agencyData.exchangeRate.split(":");

    return (finalAmountInUSDT / +firstPart) * +secondPart;
  }, [finalAmountInUSDT, agencyData]);

  if (!selectedCurrency) {
    return <RedirectTo404Page />;
  }

  const handleSubmit = () => {
    createTransaction({
      agency: agencyData.id,
      gemsAmount: +gemsAmount,
      currency: selectedCurrency,
      paymentChannel: selectedPaymentChannel
        ? selectedPaymentChannel.paymentChannel.id
        : 0,
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.final__wrapper}>
          <div
            className={cn(styles.form__field, styles.gems__input, {
              [styles.valid__error]: Boolean(validationError),
            })}
          >
            <span className={cn(styles.field__title, styles.gems__input__span)}>
              {t("transaction.gemsAmount")}
            </span>
            <input
              inputMode={"numeric"}
              value={gemsAmount}
              onChange={(e) => handleChangeGemInput(e)}
              type="number"
              min={1300}
              className={cn(styles.field__input, styles.gems__input__input)}
            />
          </div>
          {validationError && (
            <div className={styles.error}>{validationError}</div>
          )}
          <div className={styles.form__field}>
            <span className={styles.field__title}>
              {t("transaction.agency.commission")}
            </span>
            <span translate={"no"} className={styles.field__content}>
              {agencyData.commission}
            </span>
          </div>
          <div className={styles.form__field}>
            <span className={styles.field__title}>Platform Commission:</span>
            <span translate={"no"} className={styles.field__content}>
              {agencyData.aquaCommission}
            </span>
          </div>
          {isCreativePro && !selectedCurrencyIsRub && (
            <div className={styles.form__field}>
              <span className={styles.field__title}>USDT Fee:</span>
              <span translate={"no"} className={styles.field__content}>
                1
              </span>
            </div>
          )}
          <div className={cn(styles.form__field, styles.final__amount)}>
            <span className={styles.field__title}>
              {t("transaction.finalAmount")}:
            </span>
            <span translate={"no"} className={styles.field__content}>
              {finalAmountInUSDT.toFixed(2)}
            </span>
          </div>
          {Boolean(finalAmountInCurrency) && (
            <div className={cn(styles.form__field, styles.final__amount)}>
              <span className={styles.field__title}>
                Final Amount in {selectedCurrency}
              </span>
              <span translate={"no"} className={styles.field__content}>
                {finalAmountInCurrency && finalAmountInCurrency.toFixed(2)}
              </span>
            </div>
          )}
        </div>
        <Button
          onClick={handleSubmit}
          disabled={
            !userInfoIsComplete ||
            !validGemsAmount ||
            selectedOptionIndex === 0 ||
            Boolean(validationError)
          }
          variant={"secondary"}
        >
          {t("transaction.submit")}
        </Button>
      </div>
      {createTransactionResponse?.id && (
        <VerifyModal
          open={open}
          onClose={closeModal}
          payoutTransaction={createTransactionResponse.id}
        />
      )}
      <PaymentDeclineModal
        open={declineModalIsOpen}
        onClose={handleCloseDeclineModal}
      />
    </>
  );
};

export default React.memo(FinalAmountBlock);
