import React, { FC, useState } from "react";
import styles from "./HistoryItem.module.scss";
import Button from "../../../../../../components/Button/Button";
import cn from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { transactionStatusTypes } from "../../../../../../features/transaction/api/types";
import { format } from "date-fns";
import { agencyQueries } from "../../../../../../features/agency/queries";
import { updateAgencyTransactionStatusApi } from "../../../../../../features/agency/api/updateAgencyTransactionStatus/updateAgencyTransactionStatus";
import { useTranslation } from "react-i18next";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";
import { TransactionStatuses } from "../../../../../../features/common/transactions/constants";
import { AgencyTransactionHistoryDetailsType } from "../../../../../../features/agency/types";

type HistoryItemProps = {} & AgencyTransactionHistoryDetailsType;

const HistoryItem: FC<HistoryItemProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { openModal, successModalElement } = useSuccessModal({
    text: "Status successfully changed",
  });
  const { mutate: updateStatus, isLoading: updateStatusIsLoading } =
    useMutation(updateAgencyTransactionStatusApi, {
      onSuccess: () => {
        queryClient?.invalidateQueries(
          agencyQueries.transactionHistoryDetails({
            id: props.id,
            lang: i18n.language,
          }).queryKey,
        );
        queryClient?.invalidateQueries(
          agencyQueries.transactionHistory({
            lang: i18n.language,
          }).queryKey,
        );
        openModal();
      },
    });

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper__opened]: isOpen,
      })}
    >
      <div className={styles.header}>
        <span className={styles.gems}>
          {new Intl.NumberFormat("en-EN").format(props.gemsAmount)}{" "}
          {t("agency.history.gemsWord")}
        </span>
        <div className={styles.header__common}>
          <span className={styles.common__date}>
            {format(new Date(props.createdAt), "dd MMMM yyyy")}
          </span>
          {isOpen ? (
            <Button
              variant={"secondary"}
              onClick={() => setIsOpen(false)}
              className={cn(styles.btn, styles.close)}
            >
              {t("agency.history.close")}
            </Button>
          ) : (
            <Button
              variant={"secondary"}
              onClick={() => setIsOpen(true)}
              className={cn(styles.btn, styles.open)}
            >
              {t("agency.history.open")}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.header__content}>
        <div className={styles.content__header}>
          <span className={styles.content__header__item}>
            {t("agency.history.status")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("agency.history.userName")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("agency.history.currency")}
            <span className={styles.semi}>:</span>
          </span>
          <span className={styles.content__header__item}>
            {t("agency.history.payoutChannel")}
            <span className={styles.semi}>:</span>
          </span>
        </div>
        <div className={styles.content__row}>
          <div className={styles.content__row__item}>{props.status}</div>
          <div className={styles.content__row__item}>{props.streamer}</div>
          <div className={styles.content__row__item}>{props.currency}</div>
          <div className={styles.content__row__item}>{props.payoutChannel}</div>
        </div>
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.content__info}>
            <div className={styles.info__column}>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("agency.history.status")}
                </span>
                <span className={styles.info__item__content}>
                  {
                    transactionStatusTypes[
                      props.status as keyof typeof transactionStatusTypes
                    ]
                  }
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("agency.history.commission")}
                </span>
                <span className={styles.info__item__content}>
                  {props.commission}
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("agency.history.fxRate")}
                </span>
                <span className={styles.info__item__content}>
                  {props.fxRate}
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("agency.history.country")}
                </span>
                <span className={styles.info__item__content}>
                  {props.country}
                </span>
              </div>
              <div className={styles.info__item}>
                <span className={styles.info__item__title}>
                  {t("agency.history.city")}
                </span>
                <span className={styles.info__item__content}>{props.city}</span>
              </div>
            </div>
            <div className={styles.info__column}>
              {props.phone && (
                <div className={styles.info__item}>
                  <span className={styles.info__item__title}>
                    {t("agency.history.phone")}
                  </span>
                  <span className={styles.info__item__content}>
                    {props.phone}
                  </span>
                </div>
              )}
              {props.whatsapp && (
                <div className={styles.info__item}>
                  <span className={styles.info__item__title}>
                    {t("agency.history.whatsapp")}
                  </span>
                  <span className={styles.info__item__content}>
                    {props.whatsapp}
                  </span>
                </div>
              )}
              {Array.from({ length: 5 }).map((_, index) => {
                if (props[`column${index + 1}Name` as keyof typeof props]) {
                  return (
                    <div key={index} className={styles.info__item}>
                      <span className={styles.info__item__title}>
                        {props[`column${index + 1}Name` as keyof typeof props]}
                      </span>
                      <span className={styles.info__item__content}>
                        {
                          props[
                            (`column${index + 1}Value` as keyof typeof props) ??
                              null
                          ]
                        }
                      </span>
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
          {props.status === TransactionStatuses.Approved && (
            <Button
              variant={"secondary"}
              onClick={() => updateStatus({ id: props.id })}
              disabled={updateStatusIsLoading}
            >
              {t("agency.history.button")}
            </Button>
          )}
          {successModalElement}
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryItem);
