import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
  BASE_URL,
} from "../../../../utils/api/api";
import { FetchAgencyTransactionStatusListResponse } from "./types";

export const fetchAgencyTransactionStatusListApiMethod = ApiMethods.GET;

export const fetchAgencyTransactionStatusListApi =
  (): Promise<FetchAgencyTransactionStatusListResponse> =>
    appAxiosInstance[fetchAgencyTransactionStatusListApiMethod](
      BASE_URL + ApiPaths.AgencyPaths.fetchAgencyTransactionStatusList,
    ).then((res) => res.data);
