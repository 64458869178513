import React, { FC } from "react";
import {
  Skeleton as SkeletonMUI,
  SkeletonProps as SkeletonPropsMUI,
} from "@mui/material";
import styles from "./Skeleton.module.scss";

type SkeletonProps = {} & Omit<SkeletonPropsMUI, "classes" | "animation">;

const Skeleton: FC<SkeletonProps> = (props) => {
  return (
    <SkeletonMUI
      classes={{
        root: styles.root,
        circular: styles.circular,
        rounded: styles.rounded,
        withChildren: styles.withChildren,
      }}
      animation={"wave"}
      {...props}
    />
  );
};

export default Skeleton;
