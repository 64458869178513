export const maskEmail = (email: string): string => {
  const atIndex = email.lastIndexOf("@");
  if (atIndex === -1) {
    return email; // Invalid email format
  }

  const username = email.slice(0, atIndex);
  const domain = email.slice(atIndex);

  let maskedUsername = "";
  if (username.length <= 2) {
    maskedUsername = `${username.charAt(0)}${"*".repeat(username.length - 1)}`;
  } else {
    maskedUsername = `${username.slice(0, 2)}${"*".repeat(
      username.length - 2,
    )}`;
  }

  return `${maskedUsername}${domain}`;
};

export const maskPhoneNumber = (phoneNumber: string): string => {
  console.log(phoneNumber);

  const phoneNumberLength = phoneNumber.length - 3;

  return "*".repeat(phoneNumberLength) + phoneNumber.slice(-3);
};
