import React from "react";

type UseModalParams = {
  initialOpen?: boolean;
};

export const useModal = (params: UseModalParams = {}) => {
  const { initialOpen = false } = params;

  const [open, setOpen] = React.useState(initialOpen);

  const openModal = React.useCallback(() => setOpen(true), []);
  const closeModal = React.useCallback(() => setOpen(false), []);

  return { open, openModal, closeModal };
};
