import React, { FC } from "react";
import { Modal } from "../../Modal/Modal";
import { ModalProps } from "../../Modal/Modal.types";
import styles from "./SuccessModal.module.scss";
import { Icons } from "../../../constants/icons";

type SuccessModalProps = { text: string } & Pick<
  ModalProps,
  "open" | "onClose"
>;

const SuccessModal: FC<SuccessModalProps> = ({ open, text, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <Icons.Check className={styles.icon} />
        </div>
        <span className={styles.text}>{text}</span>
      </div>
    </Modal>
  );
};

export default React.memo(SuccessModal);
