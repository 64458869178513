import React, { FC } from "react";
import styles from "./StreamerPayment.module.scss";
import { useTranslation } from "react-i18next";
import StreamerDashboard from "../../StreamerDashboard";

type StreamerPaymentProps = {
  children: React.ReactNode;
};

const StreamerPayment: FC<StreamerPaymentProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <StreamerDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("streamer.payments.title")}</h1>
        <div className={styles.content}>{children}</div>
      </div>
    </StreamerDashboard>
  );
};

export default React.memo(StreamerPayment);
