import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";

export const logoutUserApiMethod = ApiMethods.GET;

export const logoutUserApi = (): Promise<unknown> => {
  return appAxiosInstance[logoutUserApiMethod](ApiPaths.UserPaths.logoutUser);
};
