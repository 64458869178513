import React from "react";
import RootWithPageLayout from "../../../../pages/common/RootWithPageLayout/RootWithPageLayout";
import { useQuery } from "@tanstack/react-query";
import { authQueries } from "../../../auth/queries";
import Loader from "../../../../components/Loader/Loader";
import { Navigate } from "react-router-dom";
import { PagesRoutes } from "../../constants";
import { AuthRoles } from "../../../auth/types";

const AdminRoutes = () => {
  const { data, isLoading, isError } = useQuery(authQueries.info);

  if (isLoading) {
    return <Loader />;
  }

  if (!data || isError || data.role !== AuthRoles.ADMIN) {
    return <Navigate to={PagesRoutes.COMMON.MAIN} />;
  }

  return <RootWithPageLayout />;
};

export default React.memo(AdminRoutes);
