import React from "react";
import VerificationInput, {
  VerificationInputProps,
} from "react-verification-input";
import styles from "./ConfirmationCodeInput.module.scss";

type ConfirmationCodeInputProps = {} & Pick<
  VerificationInputProps,
  "value" | "onChange" | "length" | "validChars" | "inputProps"
>;

const ConfirmationCodeInput: React.FC<ConfirmationCodeInputProps> = (props) => {
  return (
    <VerificationInput
      {...props}
      autoFocus={true}
      classNames={{
        container: styles.container,
        character: styles.character,
        characterInactive: styles.character__inactive,
        characterSelected: styles.character__selected,
        characterFilled: styles.character__filled,
      }}
    />
  );
};

export default ConfirmationCodeInput;
