import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchCurrencyDetailsApiParams,
  FetchCurrencyDetailsApiResponse,
} from "./types";

export const fetchCurrencyDetailsApiMethod = ApiMethods.GET;

export const fetchCurrencyDetailsApi = (
  params: FetchCurrencyDetailsApiParams,
): Promise<FetchCurrencyDetailsApiResponse> =>
  appAxiosInstance[
    fetchCurrencyDetailsApiMethod
  ]<FetchCurrencyDetailsApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyCurrencyDetailsApi,
    { params },
  ).then((res) => res.data);
