import React from "react";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";
import { PagesRoutes } from "../../../features/navigation/constants";
import styles from "./Page500.module.scss";

const Page500 = () => {
  return (
    <div className={styles.wrapper}>
      <Header withBanner={false} />
      <div className={styles.content}>
        <h1 className={styles.title}>500</h1>
        <p className={styles.description}>Something went wrong</p>
        <Link to={PagesRoutes.COMMON.MAIN} className={styles.button}>
          Go to the main page
        </Link>
      </div>
    </div>
  );
};

export default Page500;
