import React, { useEffect, useMemo } from "react";
import CurrencyFilterItem from "./components/CurrencyFilterItem/CurrencyFilterItem";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { currencyQueries } from "../../api/queries";
import { useTranslation } from "react-i18next";
import CurrencyFilterSkeleton from "./CurrencyFilter.skeleton";
import styles from "./CurrencyFilter.module.scss";

const CurrencyFilter = () => {
  const { t } = useTranslation();

  const { data: currencyFilterData, isLoading } = useQuery(
    currencyQueries.currencies,
  );

  const filteredArray = useMemo(() => {
    if (currencyFilterData) {
      const usdtObject = currencyFilterData.find(
        (obj) => obj.isoCode === "USDT",
      );

      const restArray = currencyFilterData.filter(
        (obj) => obj.isoCode !== "USDT",
      );

      if (usdtObject) {
        return [usdtObject, ...restArray];
      } else {
        return currencyFilterData;
      }
    }
  }, [currencyFilterData]);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeCurrencyCode = useMemo(() => {
    return searchParams.get("currency");
  }, [searchParams]);

  useEffect(() => {
    if (!activeCurrencyCode) {
      searchParams.set("currency", "USDT");
      setSearchParams(searchParams);
    }
  }, [activeCurrencyCode, searchParams, setSearchParams]);

  if (isLoading) {
    return <CurrencyFilterSkeleton />;
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.sectionTitle}>{t("currency.sectionTitle")}</h3>
      <div className={styles.wrapper}>
        <h5 className={styles.title}>{t("currency.title")}</h5>
        <div className={styles.content}>
          {filteredArray &&
            filteredArray.map((item) => {
              const isActive = activeCurrencyCode === item.isoCode;

              return (
                <CurrencyFilterItem
                  key={item.isoCode}
                  isoCode={item.isoCode}
                  name={item.name}
                  isActive={isActive}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CurrencyFilter;
