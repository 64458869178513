import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import styles from "./DashboardNavigation.module.scss";
import cn from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icons } from "../../constants/icons";

type StreamerDashboardMenuItemType = {
  icon: ReactNode;
  text: string;
  to: string;
};

type DashboardNavigationProps = {
  data: StreamerDashboardMenuItemType[];
  variant: "purple" | "orange";
};

const DashboardNavigation: FC<DashboardNavigationProps> = ({
  data,
  variant,
}) => {
  const [selectedItem, setSelectedItem] =
    useState<StreamerDashboardMenuItemType>(data[0]);

  const [contentIsOpen, setContentIsOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return item.to !== selectedItem.to;
    });
  }, [data, selectedItem]);

  const handleToggleContent = () => {
    setContentIsOpen(!contentIsOpen);
  };

  const location = useLocation();

  useEffect(() => {
    data.forEach((item) => {
      if (!!matchPath(item.to, location.pathname)) {
        setSelectedItem(item);
      }
    });
  }, [data, location.pathname]);

  return (
    <nav className={styles.wrapper}>
      <button
        onClick={handleToggleContent}
        className={cn(styles.aside__nav__item, [styles.active], {
          [styles.item__orange]: variant === "orange",
        })}
      >
        <div className={styles.button__content}>
          {selectedItem.icon}
          <span>{t(selectedItem.text)}</span>
        </div>
        <Icons.ArrowCircle
          className={cn(styles.arrow, {
            [styles.arrowOpen]: contentIsOpen,
            [styles.arrowOrange]: variant === "orange",
          })}
        />
      </button>
      <div
        className={cn(styles.content, {
          [styles.content__open]: contentIsOpen,
        })}
      >
        {filteredData.map((item, index) => {
          return (
            <Link to={item.to} className={styles.aside__nav__item} key={index}>
              {item.icon}
              <span>{t(item.text)}</span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default React.memo(DashboardNavigation);
