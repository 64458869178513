import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchAgencyPaymentChannelsApiParams,
  FetchAgencyPaymentChannelsApiResponse,
} from "./types";

export const fetchAgencyPaymentChannelsApiMethod = ApiMethods.GET;

export const fetchAgencyPaymentChannelsApi = (
  params: FetchAgencyPaymentChannelsApiParams,
): Promise<FetchAgencyPaymentChannelsApiResponse> =>
  appAxiosInstance[
    fetchAgencyPaymentChannelsApiMethod
  ]<FetchAgencyPaymentChannelsApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyPaymentChannelsApi,
    { params },
  ).then((res) => res.data);
