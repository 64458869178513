import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchAuthInfoApiResponse } from "./types";

export const fetchAuthInfoApiMethod = ApiMethods.GET;

export const fetchAuthInfoApi = (): Promise<FetchAuthInfoApiResponse> =>
  appAxiosInstance[fetchAuthInfoApiMethod]<FetchAuthInfoApiResponse>(
    ApiPaths.AuthPaths.fetchAuthInfo,
  ).then((res) => res.data);
