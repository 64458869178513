import React from "react";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import styles from "./CurrencyFilter.module.scss";

const CurrencyFilterSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant={"rectangular"} className={styles.skeleton__title} />
      <div className={styles.wrapper}>
        <Skeleton
          variant={"rectangular"}
          className={styles.skeleton__subtitle}
        />
        <div className={styles.content}>
          {Array.from({ length: 6 }).map((item, index) => {
            return (
              <Skeleton
                variant={"rectangular"}
                className={styles.skeleton__item}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrencyFilterSkeleton;
