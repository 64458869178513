const { NODE_ENV, REACT_APP_MODE } = process.env;

const getVariableValue = (MODE, name) =>
  process.env[`${name}_${MODE}`] || process.env[name];

const IS_PRODUCTION = NODE_ENV === "production";

const MODE = REACT_APP_MODE || (IS_PRODUCTION ? "PROD" : undefined);

const MOCK_ENABLE = Boolean(getVariableValue(MODE, "REACT_APP_MOCK_ENABLE"));

const AUTH_URL =
  process.env.REACT_APP_AUTH_URL ??
  "http://payout-back-test.aqua-creative.co/oauth2/authorization/trovo";

/** НЕ МЕНЯТЬ РАСШИРЕНИЕ С .js И НЕ УДАЛЯТЬ module.exports*/
module.exports.Config = {
  NODE_ENV,
  IS_PRODUCTION,
  MODE,
  MOCK_ENABLE,
  AUTH_URL,
};
