import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchPaymentApiParams, FetchPaymentApiResponse } from "./types";
import { PaymentType } from "../../types";

export const fetchPaymentApiMethod = ApiMethods.GET;

export const fetchPaymentApi = (
  params: FetchPaymentApiParams,
): Promise<PaymentType> =>
  appAxiosInstance[fetchPaymentApiMethod]<FetchPaymentApiResponse>(
    ApiPaths.PaymentPaths.fetchPaymentApi,
    { params },
  )
    .then((res) => res.data)
    .then((body) => body);
