import axios from "axios";
import { CurrencyPaths } from "../../features/currency/api/constants";
import { UserPaths } from "../../features/user/api/constants";
import { PaymentPaths } from "../../features/payment/api/constants";
import { TransactionPaths } from "../../features/transaction/api/constants";
import { AgencyPaths } from "../../features/agency/api/constants";
import { AuthPaths } from "../../features/auth/api/constants";
import { AdminPaths } from "../../features/admin/api/constants";

export const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export const appAxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const ApiPaths = {
  CurrencyPaths,
  UserPaths,
  PaymentPaths,
  TransactionPaths,
  AgencyPaths,
  AuthPaths,
  AdminPaths,
};

export enum ApiMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

export type LanguageQueryParam = {
  lang: string;
};
