export const AgencyPaths = {
  loginAgencyApi: "/login",
  fetchAgencyApi: "/home/agencies/id",
  fetchAgencyInfoApi: "/agency/info",
  updateAgencyInfoApi: (lang: string) => `/agency/info?lang=${lang}`,
  fetchAgencyCurrencyDetailsApi: "/agency/currency-detail",
  updateAgencyCurrencyDetailApi: (lang: string) =>
    `/agency/currency-detail?lang=${lang}`,
  fetchAgencyTransactionHistoryApi: "/agency/payout-transactions",
  fetchAgencyTransactionHistoryDetailsApi: "/agency/payout-transactions/id",
  updateAgencyTransactionStatusApi:
    "/agency/payout-transactions/id/payment-sent",
  fetchAgencyPaymentChannelsApi: "/agency/payment-channels",
  fetchUsernamesApi: "/agency/payout-transactions/usernames",
  fetchAgencyTransactionStatusList: "/agency/payout-transactions/status",
};
