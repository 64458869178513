export type RouterParams = {
  agencyId?: string;
};

type ParamsDict = { [key in keyof RouterParams]-?: `:${key}` };

const RouterParamsDict: ParamsDict = {
  agencyId: ":agencyId",
};

const home = "/";
const streamerAdmin = "/streamer_dashboard";
const agencyAdmin = "/agency_dashboard";
const transaction = `/transaction/${RouterParamsDict.agencyId}` as const;
const about = "/about";
const contacts = "/contacts";
const adminDashboard = "/control-panel";

const page403 = "/403";
const page404 = "/404";
const page500 = "/500";

const termsAndServices = "/terms_and_services";

const CommonRoutes = {
  MAIN: home,
  ABOUT: about,
  CONTACTS: contacts,
  PAGE_403: page403,
  PAGE_404: page404,
  PAGE_500: page500,
  TERMS: termsAndServices,
};

const StreamerRoutes = {
  STREAMER_ADMIN_PAYMENT: `${streamerAdmin}/payment`,
  STREAMER_ADMIN_PAYMENT_LIST: `${streamerAdmin}/payment/list`,
  STREAMER_ADMIN_PERSONAL: `${streamerAdmin}/personal`,
  STREAMER_ADMIN_HISTORY: `${streamerAdmin}/history`,
  TRANSACTION: transaction,
};

const AgencyRoutes = {
  AGENCY_ADMIN_INFORMATION: `${agencyAdmin}/information`,
  AGENCY_ADMIN_CURRENCY_DETAILS: `${agencyAdmin}/currency_details`,
  AGENCY_ADMIN_TRANSACTION_HISTORY: `${agencyAdmin}/history`,
  AGENCY_ADMIN_ANALYTICS: `${agencyAdmin}/analytics`,
};

const AdminRoutes = {
  ADMIN_TRANSACTIONS: `${adminDashboard}/transactions`,
  ADMIN_BATCH: `${adminDashboard}/batch`,
};

export const PagesRoutes = {
  STREAMER: StreamerRoutes,
  AGENCY: AgencyRoutes,
  COMMON: CommonRoutes,
  ADMIN: AdminRoutes,
} as const;
