import React, { FC, useMemo } from "react";
import AdminDashboard from "../../AdminDashboard";
import styles from "./AdminBatch.module.scss";
import { useQuery } from "@tanstack/react-query";
import { adminQueries } from "../../../../features/admin/api/queries";
import BatchItem from "./components/BatchItem/BatchItem";
import Loader from "../../../../components/Loader/Loader";
import BatchStatusFilter from "./components/BatchStatusFilter/BatchStatusFilter";
import { useSearchParams } from "react-router-dom";
import { BatchTransactionType } from "../../../../features/admin/types";
import BatchTimeSort from "../../../../components/TimeSort/TimeSort";

type AdminBatchProps = {};

const AdminBatch: FC<AdminBatchProps> = () => {
  const [searchParams] = useSearchParams();

  const { data } = useQuery(adminQueries.batchTransactions);

  const selectedStatus = useMemo(() => {
    return searchParams.get("status") ?? undefined;
  }, [searchParams]);

  const selectedSort = useMemo(() => {
    return searchParams.get("sort") ?? undefined;
  }, [searchParams]);

  const batchItems: BatchTransactionType[] = useMemo(() => {
    if (data) {
      if (!selectedSort && !selectedStatus) {
        return data;
      }

      if (selectedSort && !selectedStatus) {
        return [...data].sort((a, b) => {
          const firstDate = new Date(a.createdAt).valueOf();
          const secondDate = new Date(b.createdAt).valueOf();

          if (selectedSort === "OLDEST") {
            return firstDate - secondDate;
          } else {
            return secondDate - firstDate;
          }
        });
      }

      if (!selectedSort && selectedStatus) {
        return data.filter((item) => item.status === selectedStatus);
      }

      if (selectedStatus && selectedSort) {
        return data
          .filter((item) => item.status === selectedStatus)
          .sort((a, b) => {
            const firstDate = new Date(a.createdAt).valueOf();
            const secondDate = new Date(b.createdAt).valueOf();

            if (selectedSort === "OLDEST") {
              return firstDate - secondDate;
            } else {
              return secondDate - firstDate;
            }
          });
      }
    }

    return [];
  }, [data, selectedSort, selectedStatus]);

  if (!data) {
    return <Loader />;
  }

  return (
    <AdminDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Batch Payment</h1>
        <div className={styles.filters}>
          <BatchStatusFilter />
          <BatchTimeSort />
        </div>
        <div className={styles.content}>
          {batchItems.map((item) => {
            return <BatchItem key={item.id} {...item} />;
          })}
        </div>
      </div>
    </AdminDashboard>
  );
};

export default React.memo(AdminBatch);
