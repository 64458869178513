import React, { FC, useState } from "react";
import { Modal } from "../../../../components/Modal/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { Icons } from "../../../../constants/icons";
import { maskEmail, maskPhoneNumber } from "../../../../utils/string";
import { useModal } from "../../../../components/Modal/useModal";
import CodeModal from "../CodeModal/CodeModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { transactionQueries } from "../../../../features/transaction/api/queries";
import { sendCodeApi } from "../../../../features/transaction/api/sendCode/sendCode";
import { useTranslation } from "react-i18next";
import PaymentDeclineModal from "../PaymentDeclineModal/PaymentDeclineModal";
import styles from "./VerifyModal.module.scss";

type VerifyModalProps = { payoutTransaction: number } & Pick<
  ModalProps,
  "open" | "onClose"
>;

const VerifyModal: FC<VerifyModalProps> = (props) => {
  const { t } = useTranslation();

  const [codeModalLength, setCodeModalLength] = useState<number>(5);

  const {
    open: codeModalIsOpen,
    openModal: handleOpenCodeModal,
    closeModal: handleCloseCodeModal,
  } = useModal();

  const {
    open: declineModalIsOpen,
    openModal: handleOpenDeclineModal,
    closeModal: handleCloseDeclineModal,
  } = useModal();

  const { data: contactsData, isError: contactsDataIsError } = useQuery(
    transactionQueries.contacts,
  );

  const { mutate: sendCode, isLoading } = useMutation(sendCodeApi, {
    onSuccess: () => {
      props.onClose();
      handleOpenCodeModal();
    },
    onError: () => {
      props.onClose();
      handleOpenDeclineModal();
    },
  });

  const handleSendCode = (type: string) => {
    setCodeModalLength(5);
    sendCode({
      method: type,
    });
  };

  const handleEnterGoogleCode = () => {
    setCodeModalLength(6);
    handleOpenCodeModal();
    props.onClose();
  };

  const verifyMethodsIsEmpty =
    !contactsData?.userContact.phone &&
    !contactsData?.userContact.email &&
    !contactsData?.userContact.totpEnabled;

  if (contactsDataIsError) {
    return (
      <PaymentDeclineModal open={true} onClose={handleCloseDeclineModal} />
    );
  }

  return (
    <>
      <Modal open={props.open} onClose={props.onClose}>
        <div className={styles.wrapper}>
          <h5 className={styles.title}>
            {verifyMethodsIsEmpty
              ? t("verifyModal.titleEmpty")
              : t("verifyModal.title")}
          </h5>
          <p className={styles.description}>
            {verifyMethodsIsEmpty
              ? t("verifyModal.descriptionEmpty")
              : t("verifyModal.description")}
          </p>
          {verifyMethodsIsEmpty && (
            <a
              href="https://trovo.live/settings/two-factor-auth"
              className={styles.trovolink}
            >
              https://trovo.live/settings/two-factor-auth
            </a>
          )}
          {!verifyMethodsIsEmpty && (
            <div className={styles.content}>
              <p className={styles.content__title}>
                {t("verifyModal.sendCodeTo")}
              </p>
              <div className={styles.list}>
                {contactsData?.userContact.email && (
                  <div className={styles.item}>
                    <div className={styles.placeholder}>
                      <Icons.EmailIcon className={styles.icon} />
                      <span className={styles.text}>
                        {maskEmail(contactsData.userContact.email)}
                      </span>
                    </div>
                    <button
                      onClick={() => handleSendCode("email")}
                      className={styles.sendBtn}
                      disabled={isLoading}
                    >
                      {t("verifyModal.sendCode")}
                    </button>
                  </div>
                )}
                {contactsData?.userContact.phone && (
                  <div className={styles.item}>
                    <div className={styles.placeholder}>
                      <Icons.PhoneNumber className={styles.icon} />
                      <span className={styles.text}>
                        {maskPhoneNumber(
                          `${contactsData?.userContact.phoneArea}${contactsData.userContact.phone}`,
                        )}
                      </span>
                    </div>
                    <button
                      onClick={() => handleSendCode("phone")}
                      className={styles.sendBtn}
                      disabled={isLoading}
                    >
                      {t("verifyModal.sendCode")}
                    </button>
                  </div>
                )}
                {contactsData?.userContact.totpEnabled && (
                  <div className={styles.item}>
                    <div className={styles.placeholder}>
                      <Icons.GoogleAuth className={styles.icon__google} />
                      <span className={styles.text}>Google Authenticator</span>
                    </div>
                    <button
                      onClick={() => handleEnterGoogleCode()}
                      className={styles.sendBtn}
                      disabled={isLoading}
                    >
                      {t("verifyModal.sendCode")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <CodeModal
        open={codeModalIsOpen}
        onClose={handleCloseCodeModal}
        payoutTransaction={props.payoutTransaction}
        codeLength={codeModalLength}
      />
      <PaymentDeclineModal
        open={declineModalIsOpen}
        onClose={handleCloseDeclineModal}
      />
    </>
  );
};

export default React.memo(VerifyModal);
