import React from "react";
import { useQuery } from "@tanstack/react-query";
import { agencyQueries } from "../../features/agency/queries";
import QueryFilter from "../QueryFilter/QueryFilter";
import { useTranslation } from "react-i18next";

const UsernameFilter = () => {
  const { t } = useTranslation();

  const { data } = useQuery(agencyQueries.usernames);

  return (
    <QueryFilter
      label={"Username"}
      emptyText={t("history.all")}
      queryName={"username"}
      items={
        data?.map((item) => {
          return {
            text: item,
            value: item,
          };
        }) ?? []
      }
    />
  );
};

export default React.memo(UsernameFilter);
