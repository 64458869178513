import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  DeletePaymentChannelApiParams,
  DeletePaymentChannelApiResponse,
} from "./types";

export const deletePaymentChannelApiMethod = ApiMethods.DELETE;

export const deletePaymentChannelApi = (
  params: DeletePaymentChannelApiParams,
): Promise<DeletePaymentChannelApiResponse> =>
  appAxiosInstance[
    deletePaymentChannelApiMethod
  ]<DeletePaymentChannelApiResponse>(
    ApiPaths.UserPaths.deletePaymentChannelApi,
    { params },
  ).then((res) => res.data);
