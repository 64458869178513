import React from "react";
import { ButtonHTMLAttributes, ReactNode } from "react";
import cn from "classnames";
import styles from "./Button.module.scss";

type ButtonVariants = "primary" | "secondary";

type ButtonSizes = "default" | "small";

export type ButtonProps = {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  children?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      size = "default",
      className,
      children,
      type = "button",
      ...restProps
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={cn(
          styles.button,
          styles[variant],
          styles[size],
          {
            [styles.button__disabled]: restProps.disabled,
          },
          className,
        )}
        type={type}
        {...restProps}
      >
        {children}
      </button>
    );
  },
);

export default React.memo(Button);
