import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchAdminTransactionHistoryApiParams,
  FetchAdminTransactionHistoryApiResponse,
} from "./types";

export const fetchAdminTransactionHistoryApiMethod = ApiMethods.GET;

export const fetchAdminTransactionHistoryApi = (
  params: FetchAdminTransactionHistoryApiParams,
): Promise<FetchAdminTransactionHistoryApiResponse> =>
  appAxiosInstance[
    fetchAdminTransactionHistoryApiMethod
  ]<FetchAdminTransactionHistoryApiResponse>(
    ApiPaths.AdminPaths.fetchAdminTransactionHistoryApi,
    { params },
  ).then((res) => res.data);
