import React, { FC } from "react";
import styles from "./StreamerHistory.module.scss";
import { useTranslation } from "react-i18next";
import StreamerDashboard from "../../StreamerDashboard";
import HistoryList from "./components/HistoryList/HistoryList";
import StatusFilter from "../../../../components/StatusFilter/StatusFilter";
import CurrencyQueryFilter from "../../../../components/CurrencyQueryFilter/CurrencyQueryFilter";
import PaymentChannelFilter from "../../../../components/PaymentChannelFilter/PaymentChannelFilter";
import { useQuery } from "@tanstack/react-query";
import { paymentQueries } from "../../../../features/payment/api/queries";
import { userQueries } from "../../../../features/user/api/queries";
import TimeSort from "../../../../components/TimeSort/TimeSort";

const StreamerHistory: FC = () => {
  const { t, i18n } = useTranslation();

  const { data: paymentChannels } = useQuery(
    paymentQueries.list({
      lang: i18n.language,
    }),
  );

  const { data: statusList } = useQuery(userQueries.statusList);

  return (
    <StreamerDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("streamer.history.title")}</h1>
        <div className={styles.filters}>
          <StatusFilter
            items={statusList?.map((item) => {
              return {
                text: item,
                value: item,
              };
            })}
          />
          <CurrencyQueryFilter />
          <PaymentChannelFilter paymentChannels={paymentChannels ?? []} />
          <TimeSort />
        </div>
        <div className={styles.content}>
          <HistoryList />
        </div>
      </div>
    </StreamerDashboard>
  );
};

export default React.memo(StreamerHistory);
