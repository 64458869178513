import { ReactNode } from "react";
import { Icons } from "../../constants/icons";
import { PagesRoutes } from "../../features/navigation/constants";

type StreamerDashboardMenuItemType = {
  icon: ReactNode;
  text: string;
  to: string;
};
export const streamerDashboardMenuData: StreamerDashboardMenuItemType[] = [
  {
    icon: <Icons.Document />,
    text: "streamer.dashboard.personal",
    to: PagesRoutes.STREAMER.STREAMER_ADMIN_PERSONAL,
  },
  {
    icon: <Icons.Wallet />,
    text: "streamer.dashboard.payment",
    to: PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT,
  },
  {
    icon: <Icons.Edit />,
    text: "streamer.dashboard.transaction",
    to: PagesRoutes.STREAMER.STREAMER_ADMIN_HISTORY,
  },
  {
    icon: <Icons.Home />,
    text: "streamer.dashboard.main",
    to: PagesRoutes.COMMON.MAIN,
  },
];
