import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import StreamerDashboard from "../../StreamerDashboard";
import styles from "./StreamerPersonal.module.scss";

type StreamerPersonalProps = {
  children: React.ReactNode;
};

const StreamerPersonal: FC<StreamerPersonalProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <StreamerDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("streamer.personal.title")}</h1>
        <div className={styles.content}>{children}</div>
      </div>
    </StreamerDashboard>
  );
};

export default React.memo(StreamerPersonal);
