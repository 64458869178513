import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchBatchTransactionApiResponse } from "./types";

export const fetchBatchTransactionsApiMethod = ApiMethods.GET;

export const fetchBatchTransactionsApi =
  (): Promise<FetchBatchTransactionApiResponse> =>
    appAxiosInstance[fetchBatchTransactionsApiMethod](
      ApiPaths.AdminPaths.fetchBatchTransactionsApi,
    ).then((res) => res.data);
