import React, { FC, useMemo } from "react";
import { Popover } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { currencyQueries } from "../../../../../../features/currency/api/queries";
import Input from "../../../../../../components/Input/Input";
import styles from "./CurrencyPicker.module.scss";
import { CurrencyType } from "../../../../../../features/currency/types";
import { Icons } from "../../../../../../constants/icons";
import { useTranslation } from "react-i18next";

type CurrencyPickerProps = {
  selectedCurrencies: CurrencyType[];
  handleSelectCurrency: (currency: CurrencyType) => void;
  handleRemoveCurrency: (currency: CurrencyType) => void;
};

const CurrencyPicker: FC<CurrencyPickerProps> = ({
  selectedCurrencies,
  handleSelectCurrency,
  handleRemoveCurrency,
}) => {
  const { data: currencies } = useQuery(currencyQueries.currencies);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);

  const availableCurrencies = useMemo(() => {
    return (
      currencies?.filter((item) => !selectedCurrencies.includes(item)) ?? []
    );
  }, [currencies, selectedCurrencies]);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = !!anchorEl ? "popover" : undefined;

  const handleItemClick = (item: CurrencyType) => {
    handleSelectCurrency(item);
    setAnchorEl(null);
  };

  if (!availableCurrencies) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Input
        id={id}
        labelText={t("agency.info.currency")}
        onClick={handleClick}
        className={styles.input}
      />
      <div className={styles.selected}>
        {selectedCurrencies.map((item) => {
          return (
            <div key={item.isoCode} className={styles.selected__item}>
              <button
                className={styles.closeIcon}
                onClick={() => handleRemoveCurrency(item)}
              >
                <Icons.Close className={styles.icon} />
              </button>
              <span className={styles.selected__item__text}>
                {item.isoCode}
              </span>
            </div>
          );
        })}
      </div>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <div className={styles.list}>
          {availableCurrencies.map((item) => {
            return (
              <button
                key={item.isoCode}
                onClick={() => handleItemClick(item)}
                className={styles.item}
                type={"button"}
              >
                {item.isoCode}
              </button>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(CurrencyPicker);
