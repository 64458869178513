import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  UpdatePersonalPaymentApiParams,
  UpdatePersonalPaymentApiResponse,
} from "./types";

export const updatePersonalPaymentApiMethod = ApiMethods.PUT;

export const updatePersonalPaymentApi = (
  params: UpdatePersonalPaymentApiParams,
): Promise<UpdatePersonalPaymentApiResponse> =>
  appAxiosInstance[
    updatePersonalPaymentApiMethod
  ]<UpdatePersonalPaymentApiResponse>(
    ApiPaths.PaymentPaths.updatePersonalPaymentApi,
    params,
  ).then((res) => res.data);
