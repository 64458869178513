import React from "react";
import styles from "./HeroBanner.module.scss";
import { Icons } from "../../constants/icons";
import { useTranslation } from "react-i18next";

const HeroBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          {t("banner.titleFirst")} <br /> <span>{t("banner.titleSpan")}</span>{" "}
          {t("banner.titleSecond")}
        </h1>
        <p className={styles.description}>{t("banner.description")}</p>
      </div>
      <Icons.HeroBannerCardIcon className={styles.icon} />
    </div>
  );
};

export default HeroBanner;
