import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { VerifyCodeApiParams, VerifyCodeApiResponse } from "./types";

export const verifyCodeApiMethod = ApiMethods.POST;

export const verifyCodeApi = (
  params: VerifyCodeApiParams,
): Promise<VerifyCodeApiResponse> =>
  appAxiosInstance[verifyCodeApiMethod]<VerifyCodeApiResponse>(
    ApiPaths.TransactionPaths.verifyCodeApi,
    params,
  ).then((res) => res);
