import { SetupWorker } from "msw";
import { Config } from "../../constants/config";

export type SetupMockingServerParam = {
  enabled: boolean;
};
export const setupMockingServer = ({
  enabled,
}: SetupMockingServerParam): SetupWorker | undefined => {
  if (Config.IS_PRODUCTION || !enabled) return;

  /* without process.env.NODE_ENV === "development" checking
      tree shaking won't work */
  if (process.env.NODE_ENV === "development") {
    const { handlers } = require("./handlers");
    if (!handlers?.length) return;

    const { setupWorker } = require("msw");
    const server = setupWorker(...handlers);

    server
      .start({
        onUnhandledRequest: "bypass",
      })
      .catch(console.log.bind(console));
    console.log(
      "%cMOCKING REST API! MOCKING REST API! MOCKING REST API! MOCKING REST API!",
      `display: inline-block;
     padding: 10px; 
     background: red;
     color: black`,
    );
    server.printHandlers();
    console.log(
      "%c                                                                         ",
      `display: inline-block;
     padding: 1px; 
     background: red;
     color: black`,
    );

    return server;
  }
};
