import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchUsernamesApiResponse } from "./types";

export const fetchUsernamesApiMethod = ApiMethods.GET;

export const fetchUsernamesApi = (): Promise<FetchUsernamesApiResponse> =>
  appAxiosInstance[fetchUsernamesApiMethod]<FetchUsernamesApiResponse>(
    ApiPaths.AgencyPaths.fetchUsernamesApi,
  ).then((res) => res.data);
