import React, { FC } from "react";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../components/Modal/Modal";
import { Icons } from "../../../../constants/icons";
import styles from "./PaymentSuccessModal.module.scss";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import { PagesRoutes } from "../../../../features/navigation/constants";

type PaymentSuccessModalProps = ModalProps;
const PaymentSuccessModal: FC<PaymentSuccessModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal {...props} containerClassName={styles.modalContainer}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.content}>
            <Icons.Check className={styles.icon} />
            <div className={styles.text}>
              <h5 className={styles.title}>{t("transactionSuccess.title")}</h5>
              <p className={styles.description}>
                {t("transactionSuccess.description")}
              </p>
            </div>
          </div>
          <button onClick={props.onClose} className={styles.closeWrapper}>
            <Icons.Close className={styles.close} />
          </button>
        </div>
        <div className={styles.buttons}>
          <LinkButton to={PagesRoutes.COMMON.MAIN} onClick={props.onClose}>
            {t("transactionSuccess.button")}
          </LinkButton>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(PaymentSuccessModal);
