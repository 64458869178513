import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { SendCodeApiParams, SendCodeApiResponse } from "./types";

export const sendCodeApiMethod = ApiMethods.POST;

export const sendCodeApi = (
  params: SendCodeApiParams,
): Promise<SendCodeApiResponse> =>
  appAxiosInstance[sendCodeApiMethod]<SendCodeApiResponse>(
    ApiPaths.TransactionPaths.sendCodeApi,
    params,
  ).then((res) => res.data);
