import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  UpdateAgencyTransactionStatusParams,
  UpdateAgencyTransactionStatusResponse,
} from "./types";
import { UpdateTransactionStatusResponse } from "../../../user/api/updateTransactionStatus/types";

export const updateAgencyTransactionStatusApiMethod = ApiMethods.POST;

export const updateAgencyTransactionStatusApi = (
  params: UpdateAgencyTransactionStatusParams,
): Promise<UpdateTransactionStatusResponse> =>
  appAxiosInstance[
    updateAgencyTransactionStatusApiMethod
  ]<UpdateAgencyTransactionStatusResponse>(
    ApiPaths.AgencyPaths.updateAgencyTransactionStatusApi,
    params,
  ).then((res) => res.data);
