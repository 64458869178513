import React, { InputHTMLAttributes, useState } from "react";
import cn from "classnames";
import styles from "./Input.module.scss";
import { useTranslation } from "react-i18next";

type InputProps = {
  labelText?: string;
  error?: string;
  editable?: boolean;
  wrapperClassName?: string;
  requiredText?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapperClassName,
      editable,
      error,
      labelText,
      value,
      id,
      onChange,
      disabled,
      className,
      requiredText,
      ...restInputProps
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [isDisabled, setIsDisabled] = useState<boolean>(!!disabled);

    const [editButtonIsClicked, setEditButtonIsClicked] =
      useState<boolean>(false);

    const handleUndisableInput = () => {
      setIsDisabled(false);
      setEditButtonIsClicked(true);
    };

    return (
      <div className={cn(styles.wrapper, wrapperClassName)}>
        {labelText && (
          <label className={styles.label} htmlFor={id}>
            {requiredText ? labelText + " (* required)" : labelText}
          </label>
        )}
        {editable ? (
          <div
            className={cn(styles.container, className, {
              [styles.input__error]: error,
              [styles.editClicked]: editButtonIsClicked,
            })}
          >
            <input
              ref={ref}
              id={id}
              className={cn(styles.input, className, {
                [styles.input__disabled]: isDisabled,
              })}
              onChange={onChange}
              disabled={isDisabled}
              value={value}
              {...restInputProps}
            />
            {editable && (
              <button
                type={"button"}
                onClick={handleUndisableInput}
                className={cn(styles.edit, {
                  [styles.edit__clicked]: editButtonIsClicked,
                })}
              >
                {t("common.inputEditText")}
              </button>
            )}
          </div>
        ) : (
          <input
            ref={ref}
            id={id}
            className={cn(styles.input, styles.singeInput, className, {
              [styles.input__error]: error,
              [styles.input__disabled]: isDisabled,
            })}
            onChange={onChange}
            disabled={disabled}
            value={value}
            {...restInputProps}
          />
        )}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  },
);
export default React.memo(Input);
