import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
  BASE_URL,
} from "../../../../utils/api/api";
import {
  FetchPersonalPaymentListParams,
  FetchPersonalPaymentListResponse,
} from "./types";

export const fetchPersonalPaymentListApiMethod = ApiMethods.GET;

export const fetchPersonalPaymentListApi = (
  params: FetchPersonalPaymentListParams,
): Promise<FetchPersonalPaymentListResponse> =>
  appAxiosInstance[
    fetchPersonalPaymentListApiMethod
  ]<FetchPersonalPaymentListResponse>(
    BASE_URL + ApiPaths.PaymentPaths.fetchPersonalPaymentListApi,
    {
      params,
    },
  ).then((res) => res.data);
