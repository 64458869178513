import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { SendBatchTransactionApiParams } from "./types";

export const sendBatchTransactionApiMethod = ApiMethods.POST;

export const sendBatchTransactionApi = (
  params: SendBatchTransactionApiParams,
): Promise<unknown> =>
  appAxiosInstance[sendBatchTransactionApiMethod](
    ApiPaths.AdminPaths.sendBatchTransactionApi,
    params,
  ).then((res) => res);
