import React, { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

type GlobalStatesProps = PropsWithChildren<{
  queryClient: QueryClient;
}>;

const GlobalStates = (props: GlobalStatesProps) => {
  const { queryClient, children } = props;
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default GlobalStates;
