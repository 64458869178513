import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { LoginAgencyApiParams } from "./types";

export const loginAgencyApiMethod = ApiMethods.POST;

export const loginAgencyApi = (
  params: LoginAgencyApiParams,
): Promise<unknown> => {
  const formData = new FormData();
  formData.append("email", params.email);
  formData.append("password", params.password);
  return appAxiosInstance[loginAgencyApiMethod](
    ApiPaths.AgencyPaths.loginAgencyApi,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    },
  ).then((res) => res.data);
};
