import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { AddPaymentApiParams, AddPaymentApiResponse } from "./types";

export const addPaymentApiMethod = ApiMethods.POST;

export const addPaymentApi = (
  params: AddPaymentApiParams,
): Promise<AddPaymentApiResponse> =>
  appAxiosInstance[addPaymentApiMethod]<AddPaymentApiResponse>(
    ApiPaths.PaymentPaths.addPaymentApi,
    params,
  ).then((res) => ({ status: res.status }));
