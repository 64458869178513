import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchAgencyTransactionHistoryApiParams,
  FetchAgencyTransactionHistoryApiResponse,
} from "./types";

export const fetchAgencyTransactionHistoryApiMethod = ApiMethods.GET;

export const fetchAgencyTransactionHistoryApi = (
  params: FetchAgencyTransactionHistoryApiParams,
): Promise<FetchAgencyTransactionHistoryApiResponse> =>
  appAxiosInstance[
    fetchAgencyTransactionHistoryApiMethod
  ]<FetchAgencyTransactionHistoryApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyTransactionHistoryApi,
    { params },
  ).then((res) => res.data);
