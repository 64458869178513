import React, { FC } from "react";
import QueryFilter from "../QueryFilter/QueryFilter";
import { useQuery } from "@tanstack/react-query";
import { currencyQueries } from "../../features/currency/api/queries";
import { useTranslation } from "react-i18next";

const CurrencyQueryFilter: FC = () => {
  const { data: currenciesList } = useQuery(currencyQueries.currencies);

  const { t } = useTranslation();

  return (
    <QueryFilter
      label={t("history.currency")}
      emptyText={t("history.all")}
      queryName={"currency"}
      items={
        currenciesList?.map((item) => {
          return {
            text: item.isoCode,
            value: item.isoCode,
          };
        }) ?? []
      }
    />
  );
};

export default React.memo(CurrencyQueryFilter);
