import React, { FC, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../../components/Loader/Loader";
import HistoryItem from "../HistoryItem/HistoryItem";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { adminQueries } from "../../../../../../features/admin/api/queries";
import styles from "./HistoryList.module.scss";

type HistoryListProps = {};

const HistoryList: FC<HistoryListProps> = () => {
  const { i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const selectedStatus = useMemo(() => {
    return searchParams.get("status") ?? undefined;
  }, [searchParams]);

  const selectedCurrency = useMemo(() => {
    return searchParams.get("currency") ?? undefined;
  }, [searchParams]);

  const selectedPaymentChannel = useMemo(() => {
    return searchParams.get("paymentChannel") ?? undefined;
  }, [searchParams]);

  const { data: transactions, isLoading } = useQuery(
    adminQueries.transactionHistory({
      lang: i18n.language,
      status: selectedStatus,
      currency: selectedCurrency,
      paymentChannel: selectedPaymentChannel,
    }),
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      {transactions &&
        transactions.map((item) => {
          return <HistoryItem key={item.id} {...item} />;
        })}
    </div>
  );
};

export default React.memo(HistoryList);
