import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  DeclineAdminTransactionApiParams,
  DeclineAdminTransactionApiResponse,
} from "./types";

export const declineAdminTransactionApiMethod = ApiMethods.POST;

export const declineAdminTransactionApi = (
  params: DeclineAdminTransactionApiParams,
): Promise<DeclineAdminTransactionApiResponse> =>
  appAxiosInstance[
    declineAdminTransactionApiMethod
  ]<DeclineAdminTransactionApiResponse>(
    ApiPaths.AdminPaths.declineAdminTransactionApi,
    params,
  ).then((res) => res.data);
