import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchContactListResponse } from "./types";

export const fetchContactListApiMethod = ApiMethods.GET;

export const fetchContactListApi = (): Promise<FetchContactListResponse> =>
  appAxiosInstance[fetchContactListApiMethod]<FetchContactListResponse>(
    ApiPaths.TransactionPaths.fetchContactListApi,
  ).then((res) => res.data);
