import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { PaymentType } from "../../types";
import {
  FetchPaymentListApiParams,
  FetchPaymentListApiResponse,
} from "./types";

export const fetchPaymentListApiMethod = ApiMethods.GET;

export const fetchPaymentListApi = (
  params: FetchPaymentListApiParams,
): Promise<PaymentType[]> =>
  appAxiosInstance[fetchPaymentListApiMethod]<FetchPaymentListApiResponse>(
    ApiPaths.PaymentPaths.fetchPaymentListApi,
    {
      params,
    },
  )
    .then((res) => res.data)
    .then((body) => body);
