import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  UpdateTransactionStatusParams,
  UpdateTransactionStatusResponse,
} from "./types";

export const updateTransactionStatusApiMethod = ApiMethods.POST;

export const updateTransactionStatusApi = (
  params: UpdateTransactionStatusParams,
): Promise<UpdateTransactionStatusResponse> =>
  appAxiosInstance[
    updateTransactionStatusApiMethod
  ]<UpdateTransactionStatusResponse>(
    ApiPaths.UserPaths.updateTransactionStatus,
    params,
  ).then((res) => res.data);
