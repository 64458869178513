import React, { FC } from "react";
import styles from "./AgencyLoginModal.module.scss";
import { Icons } from "../../../../constants/icons";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import { Modal } from "../../../../components/Modal/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { loginAgencyApi } from "../../api/loginAgency/loginAgency";
import { userQueries } from "../../../user/api/queries";
import { useNavigate } from "react-router-dom";
import { PagesRoutes } from "../../../navigation/constants";
import { agencyQueries } from "../../queries";
import { useTranslation } from "react-i18next";

type AgencyLoginModalProps = {} & ModalProps;

type FormFields = {
  email: string;
  password: string;
};

const AgencyLoginModal: FC<AgencyLoginModalProps> = ({ ...restProps }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const { mutate: login } = useMutation(loginAgencyApi, {
    onSettled: () => {
      queryClient?.invalidateQueries(
        agencyQueries.agencyInfo({ lang: i18n.language }).queryKey,
      );
      queryClient?.invalidateQueries(
        userQueries.info({
          lang: i18n.language,
        }).queryKey,
      );
      navigate(PagesRoutes.AGENCY.AGENCY_ADMIN_INFORMATION, { replace: true });
    },
  });

  return (
    <Modal
      containerClassName={styles.container}
      contentClassName={styles.content}
      {...restProps}
    >
      <header className={styles.header}>
        <Icons.Logotype className={styles.logo} />
        <h4 className={styles.title}>{t("agency.login.title")}</h4>
        <button onClick={restProps.onClose} className={styles.closeWrapper}>
          <Icons.Close className={styles.close} />
        </button>
      </header>
      <div className={styles.form}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(
            values: FormFields,
            { setSubmitting }: FormikHelpers<FormFields>,
          ) => {
            login({
              email: values.email,
              password: values.password,
            });
            setSubmitting(false);
          }}
        >
          {(props: FormikProps<FormFields>) => (
            <Form>
              <div className={styles.form__wrapper}>
                <Field
                  name={"email"}
                  placeholder={t("agency.login.emailPlaceholder")}
                  as={Input}
                  type={"email"}
                  className={styles.inp}
                />
                <Field
                  name={"password"}
                  placeholder={t("agency.login.passwordPlaceholder")}
                  as={Input}
                  type={"password"}
                  className={styles.inp}
                />

                <Button
                  disabled={!props.values.email || !props.values.password}
                  type="submit"
                  className={styles.btn}
                >
                  {t("agency.login.button")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default React.memo(AgencyLoginModal);
