import React, { FC } from "react";
import { AgencyType } from "../../../../features/agency/types";
import styles from "./TransactionAgencyInfo.module.scss";
import { useTranslation } from "react-i18next";

type TransactionAgencyInfoProps = {} & AgencyType;

const TransactionAgencyInfo: FC<TransactionAgencyInfoProps> = (props) => {
  const { t } = useTranslation();

  const isCreativePro = props.name === "Pro Aqua Creative";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.icon}>{props.name[0]}</div>
        <div className={styles.header__text}>
          <h3 className={styles.header__title}>{props.name}</h3>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.payments}>
          <h5 className={styles.content__title}>
            {t("transaction.agency.payoutChannels")}
          </h5>
          <ul className={styles.payments__content}>
            {props.paymentChannels.map((item) => {
              return (
                <li key={item.id} className={styles.content__text}>
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.content__other}>
          <div className={styles.content__other__item}>
            <h5 className={styles.content__title}>
              {t("transaction.agency.commission")}
            </h5>
            <span className={styles.content__text}>{props.commission}</span>
          </div>
          <div className={styles.content__other__item}>
            <h5 className={styles.content__title}>Platform Commission:</h5>
            <span className={styles.content__text}>{props.aquaCommission}</span>
          </div>
          <div className={styles.content__other__item}>
            <h5 className={styles.content__title}>
              {t("transaction.agency.rate")}
            </h5>
            <span className={styles.content__text}>{props.exchangeRate}</span>
          </div>
        </div>
      </div>
      {isCreativePro && (
        <p className={styles.aqua}>
          * 1 USDT fee applied to Binance and Crypto Wallet
        </p>
      )}
    </div>
  );
};

export default React.memo(TransactionAgencyInfo);
