import React, { useMemo } from "react";
import CurrencyTableHeader from "./CurrencyTableHeader/CurrencyTableHeader";
import { useQuery } from "@tanstack/react-query";
import { currencyQueries } from "../../api/queries";
import { useSearchParams } from "react-router-dom";
import CurrencyTableItem from "./CurrencyTableItem/CurrencyTableItem";
import i18n from "../../../../i18n";
import { userQueries } from "../../../user/api/queries";
import CurrencyTableSkeleton from "./CurrencyTable.skeleton";
import styles from "./CurrencyTable.module.scss";

const CurrencyTable = () => {
  const [searchParams] = useSearchParams();

  const selectedCurrency = useMemo(() => {
    return searchParams.get("currency");
  }, [searchParams]);

  const { data: userInfo } = useQuery(
    userQueries.info({
      lang: "en",
    }),
  );

  const { data: agenciesData, isLoading } = useQuery(
    currencyQueries.list({
      currency: selectedCurrency ?? "egp",
      lang: i18n.language,
    }),
  );

  if (isLoading) {
    return <CurrencyTableSkeleton />;
  }

  return (
    <div className={styles.wrapper}>
      <CurrencyTableHeader selectedCurrency={selectedCurrency} />
      {agenciesData &&
        agenciesData.map((item, index) => {
          return (
            <CurrencyTableItem
              selectedCurrency={selectedCurrency}
              isAuth={!!userInfo}
              {...item}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default CurrencyTable;
