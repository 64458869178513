import React, { FC } from "react";
import styles from "./AdminDashboard.module.scss";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { useTranslation } from "react-i18next";
import DashboardNavigation from "../../components/DashboardNavigation/DashboardNavigation";
import { adminDashboardMenuData } from "./AdminDashboard.data";

type AgencyDashboardProps = {
  children: React.ReactNode;
};

const AgencyDashboard: FC<AgencyDashboardProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <main className={styles.wrapper}>
      <aside className={styles.aside}>
        <h1 className={styles.aside__title}>Admin Dashboard</h1>
        <div className={styles.aside__divider}></div>
        <nav className={styles.aside__nav}>
          {adminDashboardMenuData.map((item, index) => {
            return (
              <NavLink
                to={item.to}
                className={({ isActive }) => {
                  return cn(styles.aside__nav__item, {
                    [styles.active]: isActive,
                  });
                }}
                key={index}
              >
                {item.icon}
                <span>{t(item.text)}</span>
              </NavLink>
            );
          })}
        </nav>
      </aside>
      <div className={styles.main}>
        <DashboardHeader
          headerClassName={styles.header}
          wrapperClassName={styles.header__wrapper}
        />
        <nav className={styles.mobileNav}>
          <h2 className={styles.mobileNav__title}>Admin Dashboard</h2>
          <DashboardNavigation
            data={adminDashboardMenuData}
            variant={"orange"}
          />
        </nav>
        <div className={styles.container}>{children}</div>
      </div>
    </main>
  );
};

export default React.memo(AgencyDashboard);
