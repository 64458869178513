import { createQueryKeys } from "@lukemorales/query-key-factory";
import { FetchAdminTransactionHistoryApiParams } from "./fetchAdminTransactionHistory/types";
import { fetchAdminTransactionHistoryApi } from "./fetchAdminTransactionHistory/fetchAdminTransactionHistory";
import { FetchAdminTransactionHistoryDetailsApiParams } from "./fetchAdminTransactionHistoryDetails/types";
import { fetchAdminTransactionHistoryDetailsApi } from "./fetchAdminTransactionHistoryDetails/fetchAdminTransactionHistoryDetails";
import { fetchBatchTransactionsApi } from "./fetchBatchTransactions/fetchBatchTransactions";
import { fetchAdminTransactionStatusListApi } from "./fetchAdminTransactionStatusList/fetchAdminTransactionStatusList";

export const adminQueries = createQueryKeys("admin", {
  transactionHistory: (params: FetchAdminTransactionHistoryApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchAdminTransactionHistoryApi({ ...params }),
    };
  },
  transactionHistoryDetails: (
    params: FetchAdminTransactionHistoryDetailsApiParams,
  ) => {
    return {
      queryKey: [params],
      queryFn: () =>
        fetchAdminTransactionHistoryDetailsApi({
          id: params.id,
          lang: params.lang,
        }),
    };
  },
  batchTransactions: {
    queryKey: null,
    queryFn: () => fetchBatchTransactionsApi(),
  },
  statusList: {
    queryKey: null,
    queryFn: () => fetchAdminTransactionStatusListApi(),
  },
});
