import React, { FC } from "react";
import styles from "./UserProfile.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { PagesRoutes } from "../../../navigation/constants";
import { Icons } from "../../../../constants/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logoutUserApi } from "../../api/logoutUser/logoutUser";
import { agencyQueries } from "../../../agency/queries";
import { useTranslation } from "react-i18next";

type UserProfileProps = {
  name: string;
};

const UserProfile: FC<UserProfileProps> = ({ name }) => {
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate: logout } = useMutation(logoutUserApi, {
    onSettled: () => {
      queryClient?.invalidateQueries(
        agencyQueries.agencyInfo({ lang: i18n.language }).queryKey,
      );
    },
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(PagesRoutes.COMMON.MAIN, { replace: true });
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <Link
        to={PagesRoutes.STREAMER.STREAMER_ADMIN_PERSONAL}
        className={styles.link}
      >
        <div className={styles.iconWrapper}>
          <Icons.UserProfile className={styles.icon} />
        </div>
        <span className={styles.name}>{name}</span>
      </Link>
      <span className={styles.divider}>/</span>
      <button onClick={handleLogout} className={styles.logout}>
        {t("header.logout")}
      </button>
    </div>
  );
};

export default React.memo(UserProfile);
