import React, { FC, useMemo } from "react";
import styles from "./DashboardHeader.module.scss";
import { Link } from "react-router-dom";
import { PagesRoutes } from "../../features/navigation/constants";
import cn from "classnames";
import { useModal } from "../Modal/useModal";
import AuthModal from "../../features/auth/components/AuthModal/AuthModal";
import { useQuery } from "@tanstack/react-query";
import { userQueries } from "../../features/user/api/queries";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import { Icons } from "../../constants/icons";
import MobileMenu from "../MobileMenu/MobileMenu";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import UserProfile from "../../features/user/components/UserProfile/UserProfile";
import { agencyQueries } from "../../features/agency/queries";
import AgencyProfile from "../../features/agency/components/AgencyProfile/AgencyProfile";
import { authQueries } from "../../features/auth/queries";
import AdminProfile from "../../features/admin/components/AdminProfile/AdminProfile";

type DashboardHeaderProps = {
  headerClassName?: string;
  wrapperClassName?: string;
};

const DashboardHeader: FC<DashboardHeaderProps> = ({
  headerClassName,
  wrapperClassName,
}) => {
  const { t, i18n } = useTranslation();

  const { data: user } = useQuery(
    userQueries.info({
      lang: i18n.language,
    }),
  );

  const { data: agencyInfo } = useQuery(
    agencyQueries.agencyInfo({
      lang: i18n.language,
    }),
  );

  const { data: authInfo } = useQuery(authQueries.info);

  const {
    open: mobileMenuOpen,
    openModal: openMobileMenuModal,
    closeModal: closeMobileMenuModal,
  } = useModal();

  const { open, closeModal, openModal } = useModal();

  const handleCloseMobileMenu = () => {
    if (mobileMenuOpen) {
      closeMobileMenuModal();
    } else {
      openMobileMenuModal();
    }
  };

  const authElement = useMemo(() => {
    if (authInfo?.role === "STREAMER") {
      return <UserProfile name={user?.trovoUsername ?? ""} />;
    }

    if (authInfo?.role === "AGENCY") {
      return <AgencyProfile name={agencyInfo?.agencyName ?? ""} />;
    }

    if (authInfo?.role === "ADMIN") {
      return <AdminProfile name={"Admin"} />;
    }

    return <Button onClick={openModal}>{t("header.sign")}</Button>;
  }, [user, agencyInfo, authInfo, openModal, t]);

  return (
    <>
      <header className={cn(styles.header, headerClassName)}>
        <div
          className={cn(styles.header__wrapper, wrapperClassName, {
            [styles.header__wrapper__menu]: mobileMenuOpen,
          })}
        >
          <div className={styles.navigation}>
            <Link to={PagesRoutes.COMMON.MAIN}>
              <Icons.Logotype className={styles.logo} />
            </Link>
            <nav className={styles.navigation__list}>
              <Link to={PagesRoutes.COMMON.MAIN} className={styles.link}>
                {t("header.payment")}
              </Link>
            </nav>
          </div>
          <div className={styles.common}>
            <LanguageToggle wrapperClassName={styles.langs} />
            {authElement}
            <button
              className={cn(styles.burger, {
                [styles.burgerOpen]: mobileMenuOpen,
              })}
              onClick={handleCloseMobileMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <MobileMenu
        isOpen={mobileMenuOpen}
        handleCloseMobileMenu={closeMobileMenuModal}
      />
      <AuthModal open={open} onClose={closeModal} />
    </>
  );
};

export default DashboardHeader;
