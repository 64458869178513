import React, { FC } from "react";
import styles from "./StreamerDashboard.module.scss";
import { streamerDashboardMenuData } from "./StreamerDashboard.data";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { useTranslation } from "react-i18next";
import DashboardNavigation from "../../components/DashboardNavigation/DashboardNavigation";

type StreamerDashboardProps = {
  children: React.ReactNode;
};

const StreamerDashboard: FC<StreamerDashboardProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <main className={styles.wrapper}>
      <aside className={styles.aside}>
        <h1 className={styles.aside__title}>{t("streamer.dashboard.title")}</h1>
        <div className={styles.aside__divider}></div>
        <nav className={styles.aside__nav}>
          {streamerDashboardMenuData.map((item, index) => {
            return (
              <NavLink
                to={item.to}
                className={({ isActive }) => {
                  return cn(styles.aside__nav__item, {
                    [styles.active]: isActive,
                  });
                }}
                key={index}
              >
                {item.icon}
                <span>{t(item.text)}</span>
              </NavLink>
            );
          })}
        </nav>
      </aside>
      <div className={styles.main}>
        <DashboardHeader
          headerClassName={styles.header}
          wrapperClassName={styles.header__wrapper}
        />
        <nav className={styles.mobileNav}>
          <h2 className={styles.mobileNav__title}>Dashboard</h2>
          <DashboardNavigation
            data={streamerDashboardMenuData}
            variant={"purple"}
          />
        </nav>
        <div className={styles.container}>{children}</div>
      </div>
    </main>
  );
};

export default React.memo(StreamerDashboard);
