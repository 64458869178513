import React from "react";
import Loader from "../Loader/Loader";
import styles from "./FullScreenLoader.module.scss";

const FullScreenLoader = () => {
  return (
    <div className={styles.overlay}>
      <Loader />
    </div>
  );
};

export default FullScreenLoader;
