import React, { FC } from "react";
import styles from "./AgencyHistory.module.scss";
import AgencyDashboard from "../../AgencyDashboard";
import HistoryList from "./components/HistoryList/HistoryList";
import { useTranslation } from "react-i18next";
import CurrencyQueryFilter from "../../../../components/CurrencyQueryFilter/CurrencyQueryFilter";
import PaymentChannelFilter from "../../../../components/PaymentChannelFilter/PaymentChannelFilter";
import { useQuery } from "@tanstack/react-query";
import { agencyQueries } from "../../../../features/agency/queries";
import StatusFilter from "../../../../components/StatusFilter/StatusFilter";
import UsernameFilter from "../../../../components/UsernameFilter/UsernameFilter";
import TimeSort from "../../../../components/TimeSort/TimeSort";

type AgencyHistoryProps = {};

const AgencyHistory: FC<AgencyHistoryProps> = () => {
  const { t, i18n } = useTranslation();

  const { data: paymentChannels } = useQuery(
    agencyQueries.agencyPaymentChannels({
      lang: i18n.language,
    }),
  );

  const { data: statusList } = useQuery(agencyQueries.statusList);

  return (
    <AgencyDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("agency.history.title")}</h1>
        <div className={styles.filters}>
          <StatusFilter
            items={statusList?.map((item) => {
              return {
                value: item,
                text: item,
              };
            })}
          />
          <CurrencyQueryFilter />
          <PaymentChannelFilter paymentChannels={paymentChannels ?? []} />
          <UsernameFilter />
          <TimeSort />
        </div>
        <div className={styles.content}>
          <HistoryList />
        </div>
      </div>
    </AgencyDashboard>
  );
};

export default React.memo(AgencyHistory);
