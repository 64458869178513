import React, { FC, useState } from "react";
import styles from "./StreamerPaymentList.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { paymentQueries } from "../../../../../../features/payment/api/queries";
import Loader from "../../../../../../components/Loader/Loader";
import Button from "../../../../../../components/Button/Button";
import { useModal } from "../../../../../../components/Modal/useModal";
import PaymentModal from "../AddPaymentModal/AddPaymentModal";
const StreamerPaymentList: FC = () => {
  const { open, openModal, closeModal } = useModal();
  const [selectedPaymentChannelId, setSelectedPaymentChannelId] = useState<
    null | number
  >(null);

  const { t, i18n } = useTranslation();

  const { data, isLoading } = useQuery(
    paymentQueries.list({
      lang: i18n.language,
    }),
  );

  const handleOpenModal = (id: number) => {
    setSelectedPaymentChannelId(id);
    openModal();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {data.map((item) => {
        return (
          <div key={item.id} className={styles.item}>
            <span className={styles.item__text}>{item.name}</span>
            <Button
              onClick={() => handleOpenModal(item.id)}
              className={styles.item__btn}
            >
              {t("streamer.payments.select")}
            </Button>
          </div>
        );
      })}
      <PaymentModal
        open={open}
        onClose={closeModal}
        paymentChannelId={selectedPaymentChannelId}
      />
    </div>
  );
};

export default React.memo(StreamerPaymentList);
