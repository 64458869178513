import React, { FC } from "react";
import { Link } from "react-router-dom";
import { PagesRoutes } from "../../../../../../features/navigation/constants";
import styles from "./PersonalErrorPlaceholder.module.scss";

type PersonalErrorPlaceholderProps = {};

const PersonalErrorPlaceholder: FC<PersonalErrorPlaceholderProps> = () => {
  return (
    <div className={styles.error}>
      <p className={styles.error__text}>
        Please, complete the registration or fill in all the fields:{" "}
        <Link
          to={PagesRoutes.STREAMER.STREAMER_ADMIN_PERSONAL}
          className={styles.error__link}
        >
          click here
        </Link>
      </p>
    </div>
  );
};

export default React.memo(PersonalErrorPlaceholder);
