import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchAgencyTransactionHistoryDetailsApiParams,
  FetchAgencyTransactionHistoryDetailsApiResponse,
} from "./types";

export const fetchAgencyTransactionHistoryDetailsApiMethod = ApiMethods.GET;

export const fetchAgencyTransactionHistoryDetailsApi = (
  params: FetchAgencyTransactionHistoryDetailsApiParams,
): Promise<FetchAgencyTransactionHistoryDetailsApiResponse> =>
  appAxiosInstance[
    fetchAgencyTransactionHistoryDetailsApiMethod
  ]<FetchAgencyTransactionHistoryDetailsApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyTransactionHistoryDetailsApi,
    {
      params,
    },
  ).then((res) => res.data);
