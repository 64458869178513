import React, { FC } from "react";
import styles from "./PaymentDeclineModal.module.scss";
import { Modal } from "../../../../components/Modal/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { Icons } from "../../../../constants/icons";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";
import { PagesRoutes } from "../../../../features/navigation/constants";
import LinkButton from "../../../../components/LinkButton/LinkButton";

type PaymentDeclineModalProps = ModalProps;

const PaymentDeclineModal: FC<PaymentDeclineModalProps> = (props) => {
  const { t } = useTranslation();

  const handleYesClick = () => {
    window.location.reload();
    props.onClose();
  };

  return (
    <Modal {...props} containerClassName={styles.modalContainer}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.content}>
            <Icons.Error className={styles.icon} />
            <div className={styles.text}>
              <h5 className={styles.title}>{t("transactionDecline.title")}</h5>
              <p className={styles.description}>
                {t("transactionDecline.description")}
              </p>
            </div>
          </div>
          <button onClick={handleYesClick} className={styles.closeWrapper}>
            <Icons.Close className={styles.close} />
          </button>
        </div>
        <div className={styles.buttons}>
          <Button onClick={handleYesClick}>
            {t("transactionDecline.yes")}
          </Button>
          <LinkButton to={PagesRoutes.COMMON.MAIN} className={styles.outlined}>
            {t("transactionDecline.no")}
          </LinkButton>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(PaymentDeclineModal);
