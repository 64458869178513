import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchAdminTransactionHistoryDetailsApiParams,
  FetchAdminTransactionHistoryDetailsApiResponse,
} from "./types";

export const fetchAdminTransactionHistoryDetailsApiMethod = ApiMethods.GET;

export const fetchAdminTransactionHistoryDetailsApi = (
  params: FetchAdminTransactionHistoryDetailsApiParams,
): Promise<FetchAdminTransactionHistoryDetailsApiResponse> =>
  appAxiosInstance[
    fetchAdminTransactionHistoryDetailsApiMethod
  ]<FetchAdminTransactionHistoryDetailsApiResponse>(
    ApiPaths.AdminPaths.fetchAdminTransactionHistoryDetailsApi,
    {
      params,
    },
  ).then((res) => res.data);
