import React, { FC, useMemo } from "react";
import { PaymentType } from "../../../../../../features/payment/types";
import styles from "./PaymentPicker.module.scss";
import { useQuery } from "@tanstack/react-query";
import Input from "../../../../../../components/Input/Input";
import { Icons } from "../../../../../../constants/icons";
import { Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { agencyQueries } from "../../../../../../features/agency/queries";

type PaymentPickerProps = {
  selectedPayments: PaymentType[];
  handleSelectPayment: (payment: PaymentType) => void;
  handleRemovePayment: (payment: PaymentType) => void;
};

const PaymentPicker: FC<PaymentPickerProps> = ({
  selectedPayments,
  handleSelectPayment,
  handleRemovePayment,
}) => {
  const { t, i18n } = useTranslation();

  const { data: allPayments } = useQuery(
    agencyQueries.agencyPaymentChannels({
      lang: i18n.language,
    }),
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);

  const availablePayments = useMemo(() => {
    return (
      allPayments?.filter((item) => !selectedPayments.includes(item)) ?? []
    );
  }, [allPayments, selectedPayments]);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: PaymentType) => {
    handleSelectPayment(item);
    setAnchorEl(null);
  };

  const id = !!anchorEl ? "popover" : undefined;

  return (
    <div className={styles.wrapper}>
      {!!availablePayments.length ? (
        <Input
          id={id}
          labelText={t("agency.currency.selectPayments")}
          onClick={handleClick}
          className={styles.input}
        />
      ) : (
        <span className={cn(styles.selectedPayment, styles.input)}>
          {t("agency.currency.selectPayments")}
        </span>
      )}
      <div className={styles.selected}>
        {selectedPayments.map((item) => {
          return (
            <div key={item.id} className={styles.selected__item}>
              <button
                className={styles.closeIcon}
                onClick={() => handleRemovePayment(item)}
              >
                <Icons.Close className={styles.icon} />
              </button>
              <span className={styles.selected__item__text}>{item.name}</span>
            </div>
          );
        })}
      </div>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <div className={styles.list}>
          {availablePayments.map((item) => {
            return (
              <button
                key={item.id}
                onClick={() => handleItemClick(item)}
                className={styles.item}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(PaymentPicker);
