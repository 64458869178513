import React, { FC } from "react";
import { Modal } from "../../../../../../components/Modal/Modal";
import { ModalProps } from "../../../../../../components/Modal/Modal.types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { paymentQueries } from "../../../../../../features/payment/api/queries";
import { addPaymentApi } from "../../../../../../features/payment/api/addPayment/addPayment";
import Loader from "../../../../../../components/Loader/Loader";
import { RedirectTo404Page } from "../../../../../common/RedirectTo404Page/RedirectTo404Page";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Input from "../../../../../../components/Input/Input";
import Button from "../../../../../../components/Button/Button";
import { Icons } from "../../../../../../constants/icons";
import { PagesRoutes } from "../../../../../../features/navigation/constants";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";
import styles from "./AddPaymentModal.module.scss";
import { useErrorModal } from "../../../../../../components/modals/ErrorModal/useErrorModal";

type PaymentModalProps = {
  paymentChannelId: number | null;
} & ModalProps;

type FormFields = {
  column1?: string;
  column2?: string;
  column3?: string;
  column4?: string;
  column5?: string;
};

const AddPaymentModal: FC<PaymentModalProps> = ({
  paymentChannelId,
  ...restProps
}) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    paymentQueries.payment({
      id: paymentChannelId ? paymentChannelId : undefined,
      lang: i18n.language,
    }),
  );

  const { openModal, successModalElement } = useSuccessModal({
    text: "Success!",
  });

  const { openModal: openErrorModal, errorModalElement } = useErrorModal({});

  const navigate = useNavigate();

  const { mutate: addPayment } = useMutation(addPaymentApi, {
    onSuccess: () => {
      queryClient?.invalidateQueries(
        paymentQueries.personalList({
          lang: i18n.language,
        }).queryKey,
      );
      openModal();
      setTimeout(() => {
        navigate(PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT, {
          replace: true,
        });
      }, 3000);
    },
    onError: (error) => {
      openErrorModal();
    },
  });

  if (!paymentChannelId) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data?.id) {
    return <RedirectTo404Page />;
  }

  return (
    <>
      {" "}
      <Modal
        containerClassName={styles.container}
        contentClassName={styles.content}
        {...restProps}
      >
        <header className={styles.header}>
          <h4 className={styles.title}>{data.name}</h4>
          <button onClick={restProps.onClose} className={styles.closeWrapper}>
            <Icons.Close className={styles.close} />
          </button>
        </header>
        <h3 className={styles.modalTitle}>
          {t("streamer.payments.item.addAccount")}
        </h3>
        <div className={styles.form}>
          <Formik
            initialValues={{}}
            onSubmit={(
              values: FormFields,
              { setSubmitting }: FormikHelpers<FormFields>,
            ) => {
              addPayment({
                id: data.id,
                column1: values.column1 === "" ? null : values.column1,
                column2: values.column2 === "" ? null : values.column2,
                column3: values.column3 === "" ? null : values.column3,
                column4: values.column4 === "" ? null : values.column4,
                column5: values.column5 === "" ? null : values.column5,
              });
              setSubmitting(false);
            }}
          >
            {(props: FormikProps<FormFields>) => (
              <Form>
                <div className={styles.form__wrapper}>
                  {data?.column1Name && (
                    <Field
                      name="column1"
                      as={Input}
                      labelText={data?.column1Name}
                      className={styles.inp}
                    />
                  )}

                  {data?.column2Name && (
                    <Field
                      name="column2"
                      as={Input}
                      labelText={data?.column2Name}
                      className={styles.inp}
                    />
                  )}

                  {data?.column3Name && (
                    <Field
                      name="column3"
                      as={Input}
                      labelText={data?.column3Name}
                      className={styles.inp}
                    />
                  )}

                  {data?.column4Name && (
                    <Field
                      name="column4"
                      as={Input}
                      labelText={data?.column4Name}
                      className={styles.inp}
                    />
                  )}

                  {data?.column5Name && (
                    <Field
                      name="column5"
                      as={Input}
                      labelText={data?.column5Name}
                      className={styles.inp}
                    />
                  )}

                  <Button
                    disabled={Boolean(
                      (data?.column1Name &&
                        props.values.column1 &&
                        props.values.column1.length === 0) ||
                        (data?.column2Name &&
                          props.values.column2 &&
                          props.values.column2.length === 0) ||
                        (data?.column3Name &&
                          props.values.column3 &&
                          props.values.column3.length === 0) ||
                        (data?.column4Name &&
                          props.values.column4 &&
                          props.values.column4.length === 0) ||
                        (data?.column5Name &&
                          props.values.column5 &&
                          props.values.column5.length === 0),
                    )}
                    type="submit"
                    className={styles.btn}
                  >
                    {t("streamer.payments.submit")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <span className={styles.description}>
          {t("streamer.payments.item.description")}
        </span>
      </Modal>
      {successModalElement}
      {errorModalElement}
    </>
  );
};

export default React.memo(AddPaymentModal);
