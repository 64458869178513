import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import LinkButton from "../../../../../../../../components/LinkButton/LinkButton";
import { PagesRoutes } from "../../../../../../../../features/navigation/constants";
import styles from "./StreamerPersonalPaymentsEmpty.module.scss";

type StreamerPersonalPaymentsEmptyProps = {};

const StreamerPersonalPaymentsEmpty: FC<
  StreamerPersonalPaymentsEmptyProps
> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p className={styles.description}>{t("streamer.payments.empty")}</p>
      <LinkButton
        to={PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT_LIST}
        className={styles.btn}
      >
        {t("streamer.payments.addMethod")}
      </LinkButton>
    </div>
  );
};

export default React.memo(StreamerPersonalPaymentsEmpty);
