import React, { FC, useState } from "react";
import * as Yup from "yup";
import AgencyDashboard from "../../AgencyDashboard";
import CurrencyPicker from "./components/CurrencyPicker/CurrencyPicker";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { agencyQueries } from "../../../../features/agency/queries";
import { CurrencyType } from "../../../../features/currency/types";
import { AgencyInfoType } from "../../../../features/agency/types";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import { updateAgencyInfoApi } from "../../../../features/agency/api/updateAgencyInfo/updateAgencyInfo";
import { useTranslation } from "react-i18next";
import { useSuccessModal } from "../../../../components/modals/SuccessModal/useSuccessModal";
import styles from "./AgencyInformation.module.scss";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  agencyName: Yup.string().required("Agency Name is required"),
  whatsapp: Yup.string().required("Whatsapp or Telegram is required"),
});

type AgencyInformationProps = {};

type FormFieldsType = Omit<AgencyInfoType, "id" | "currencies" | "agencyEmail">;

const AgencyInformation: FC<AgencyInformationProps> = () => {
  const queryClient = useQueryClient();

  const { t, i18n } = useTranslation();

  const { data: agencyData } = useQuery(
    agencyQueries.agencyInfo({ lang: i18n.language }),
  );

  const { openModal, successModalElement } = useSuccessModal({
    text: "Information successfully saved",
  });

  const { mutate: updateAgencyInfo } = useMutation(updateAgencyInfoApi, {
    onSuccess: () => {
      queryClient?.invalidateQueries(
        agencyQueries.agencyInfo({ lang: i18n.language }).queryKey,
      );
      openModal();
    },
  });

  const [selectedCurrencies, setSelectedCurrencies] = useState<CurrencyType[]>(
    agencyData?.currencies ?? [],
  );

  const handleSelectCurrency = (currency: CurrencyType) => {
    setSelectedCurrencies((prevState) => [...prevState, currency]);
  };

  const handleRemoveCurrency = (currency: CurrencyType) => {
    setSelectedCurrencies((prevState) =>
      prevState.filter((item) => {
        return item.isoCode !== currency.isoCode;
      }),
    );
  };

  if (!agencyData) {
    return null;
  }

  return (
    <AgencyDashboard>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("agency.info.title")}</h1>
        <Formik
          initialValues={{
            fullName: agencyData.fullName,
            agencyName: agencyData.agencyName,
            aquaCommission: agencyData.aquaCommission,
            whatsapp: agencyData.whatsapp,
            binanceEmail: agencyData.binanceEmail,
            binanceId: agencyData.binanceId,
          }}
          validationSchema={validationSchema}
          onSubmit={(
            values: FormFieldsType,
            { setSubmitting }: FormikHelpers<FormFieldsType>,
          ) => {
            updateAgencyInfo({
              id: agencyData.id,
              currencies: selectedCurrencies,
              lang: i18n.language,
              agencyEmail: agencyData.agencyEmail,
              ...values,
            });
          }}
        >
          {({ errors }: FormikProps<FormFieldsType>) => (
            <Form>
              <div className={styles.form}>
                <Field
                  name="fullName"
                  as={Input}
                  labelText={t("agency.info.fullName")}
                  wrapperClassName={styles.inp}
                  editable={true}
                  disabled={true}
                  error={errors.fullName}
                />
                <Field
                  name="aquaCommission"
                  as={Input}
                  labelText={t("agency.info.commission")}
                  wrapperClassName={styles.inp}
                  editable={false}
                  disabled={true}
                />
                <Field
                  name="agencyName"
                  as={Input}
                  labelText={t("agency.info.agencyName")}
                  wrapperClassName={styles.inp}
                  editable={true}
                  disabled={true}
                  error={errors.agencyName}
                />
                <Field
                  name="whatsapp"
                  as={Input}
                  labelText={t("agency.info.whatsapp")}
                  wrapperClassName={styles.inp}
                  editable={true}
                  disabled={true}
                  error={errors.whatsapp}
                />
                <Field
                  name="binanceId"
                  as={Input}
                  labelText={t("agency.info.binanceId")}
                  wrapperClassName={styles.inp}
                  editable={true}
                  disabled={true}
                />
                <Field
                  name="binanceEmail"
                  as={Input}
                  labelText={t("agency.info.binanceEmail")}
                  wrapperClassName={styles.inp}
                  editable={true}
                  disabled={true}
                />
                <CurrencyPicker
                  selectedCurrencies={selectedCurrencies}
                  handleSelectCurrency={handleSelectCurrency}
                  handleRemoveCurrency={handleRemoveCurrency}
                />
              </div>
              <Button
                variant={"secondary"}
                type="submit"
                className={styles.btn}
              >
                {t("agency.info.button")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      {successModalElement}
    </AgencyDashboard>
  );
};

export default React.memo(AgencyInformation);
