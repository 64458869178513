import React, { FC } from "react";
import styles from "./AdminProfile.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logoutUserApi } from "../../../user/api/logoutUser/logoutUser";
import { Link, useNavigate } from "react-router-dom";
import { PagesRoutes } from "../../../navigation/constants";
import { Icons } from "../../../../constants/icons";
import { useTranslation } from "react-i18next";
import { authQueries } from "../../../auth/queries";

type AdminProfileProps = {
  name: string;
};

const AdminProfile: FC<AdminProfileProps> = ({ name }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate: logout } = useMutation(logoutUserApi, {
    onSettled: () => {
      queryClient?.invalidateQueries(authQueries.info.queryKey);
    },
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(PagesRoutes.COMMON.MAIN, { replace: true });
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <Link to={PagesRoutes.ADMIN.ADMIN_TRANSACTIONS} className={styles.link}>
        <div className={styles.iconWrapper}>
          <Icons.UserProfile className={styles.icon} />
        </div>
        <span className={styles.name}>{name}</span>
      </Link>
      <span className={styles.divider}>/</span>
      <button onClick={handleLogout} className={styles.logout}>
        {t("header.logout")}
      </button>
    </div>
  );
};

export default React.memo(AdminProfile);
