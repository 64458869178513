import React, { FC } from "react";
import { PaymentType } from "../../features/payment/types";
import QueryFilter from "../QueryFilter/QueryFilter";
import { useTranslation } from "react-i18next";

type PaymentChannelFilterProps = {
  paymentChannels: PaymentType[];
};

const PaymentChannelFilter: FC<PaymentChannelFilterProps> = ({
  paymentChannels,
}) => {
  const { t } = useTranslation();

  return (
    <QueryFilter
      label={t("history.payoutChannel")}
      emptyText={t("history.all")}
      queryName={"paymentChannel"}
      items={
        paymentChannels.map((item) => {
          return {
            text: item.name,
            value: String(item.id),
          };
        }) ?? []
      }
    />
  );
};

export default React.memo(PaymentChannelFilter);
