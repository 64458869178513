import React, { FC, useState } from "react";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { Modal } from "../../../../components/Modal/Modal";
import ConfirmationCodeInput from "../../../../components/ConfirmationCodeInput/ConfirmationCodeInput";
import { useMutation } from "@tanstack/react-query";
import { verifyCodeApi } from "../../../../features/transaction/api/verifyCode/verifyCode";
import { useTranslation } from "react-i18next";
import trovoLogo from "../../../../assets/images/trovoLogo.png";
import { useModal } from "../../../../components/Modal/useModal";
import PaymentDeclineModal from "../PaymentDeclineModal/PaymentDeclineModal";
import PaymentSuccessModal from "../PaymentSuccessModal/PaymentSuccessModal";
import styles from "./CodeModal.module.scss";
import cn from "classnames";

type CodeModalProps = {
  payoutTransaction: number;
  codeLength: number;
} & Pick<ModalProps, "open" | "onClose">;

const CodeModal: FC<CodeModalProps> = ({
  open,
  onClose,
  payoutTransaction,
  codeLength,
}) => {
  const {
    open: declineModalIsOpen,
    openModal: handleOpenDeclineModal,
    closeModal: handleCloseDeclineModal,
  } = useModal();

  const {
    open: successModalIsOpen,
    openModal: handleOpenSuccessModal,
    closeModal: handleCloseSuccessModal,
  } = useModal();

  const { t } = useTranslation();

  const [confirmationCode, setConfirmationCode] = useState<string>("");

  const { mutate: verifyCode } = useMutation(verifyCodeApi, {
    onSuccess: () => {
      onClose();
      handleOpenSuccessModal();
    },
    onError: (error) => {
      onClose();
      handleOpenDeclineModal();
    },
  });

  const handleCodeChange = (value: string) => {
    setConfirmationCode(value);
  };

  const handleConfirmCode = () => {
    verifyCode({
      code: confirmationCode,
      payoutTransaction: payoutTransaction,
      totpEnabled: codeLength === 6,
    });
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className={styles.wrapper}>
          <img src={trovoLogo} alt="Trovo" className={styles.trovoLogo} />
          <h3 className={styles.title}>{t("codeModal.title")}</h3>
          <ConfirmationCodeInput
            length={codeLength}
            validChars="0-9"
            inputProps={{ inputMode: "numeric" }}
            value={confirmationCode}
            onChange={handleCodeChange}
          />
          <button
            onClick={handleConfirmCode}
            className={cn(styles.btn, {
              [styles.btn__visible]: confirmationCode.length >= codeLength,
            })}
            disabled={confirmationCode.length < codeLength}
          >
            {t("codeModal.confirm")}
          </button>
        </div>
      </Modal>
      <PaymentDeclineModal
        open={declineModalIsOpen}
        onClose={handleCloseDeclineModal}
      />
      <PaymentSuccessModal
        open={successModalIsOpen}
        onClose={handleCloseSuccessModal}
      />
    </>
  );
};

export default React.memo(CodeModal);
