import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchUserTransactionStatusListApiResponse } from "./types";

export const fetchUserTransactionStatusListApiMethod = ApiMethods.GET;

export const fetchUserTransactionStatusListApi =
  (): Promise<FetchUserTransactionStatusListApiResponse> =>
    appAxiosInstance[fetchUserTransactionStatusListApiMethod](
      ApiPaths.UserPaths.fetchUserTransactionStatusList,
    ).then((res) => res.data);
