import React, { FC, useMemo } from "react";
import styles from "./HistoryList.module.scss";
import { useQuery } from "@tanstack/react-query";
import { userQueries } from "../../../../../../features/user/api/queries";
import Loader from "../../../../../../components/Loader/Loader";
import HistoryItem from "../HistoryItem/HistoryItem";
import { RedirectTo404Page } from "../../../../../common/RedirectTo404Page/RedirectTo404Page";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type HistoryListProps = {};

const HistoryList: FC<HistoryListProps> = () => {
  const { i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const selectedStatus = useMemo(() => {
    return searchParams.get("status") ?? undefined;
  }, [searchParams]);

  const selectedCurrency = useMemo(() => {
    return searchParams.get("currency") ?? undefined;
  }, [searchParams]);

  const selectedPaymentChannel = useMemo(() => {
    return searchParams.get("paymentChannel") ?? undefined;
  }, [searchParams]);

  const selectedSort = useMemo(() => {
    return searchParams.get("sort") ?? undefined;
  }, [searchParams]);

  const { data: transactions, isLoading } = useQuery(
    userQueries.transactionHistory({
      lang: i18n.language,
      status: selectedStatus,
      currency: selectedCurrency,
      paymentChannel: selectedPaymentChannel,
    }),
  );

  const { data: personalInfo } = useQuery(
    userQueries.info({
      lang: i18n.language,
    }),
  );

  const transactionsData = useMemo(() => {
    if (!transactions) {
      return [];
    }

    if (!selectedSort) {
      return transactions;
    }

    return [...transactions].sort((a, b) => {
      const firstDate = new Date(a.createdAt).valueOf();
      const secondDate = new Date(b.createdAt).valueOf();

      if (selectedSort === "OLDEST") {
        return firstDate - secondDate;
      } else {
        return secondDate - firstDate;
      }
    });
  }, [transactions, selectedSort]);

  if (isLoading) {
    return <Loader />;
  }

  if (!personalInfo) {
    return <RedirectTo404Page />;
  }

  return (
    <div className={styles.wrapper}>
      {transactionsData &&
        transactionsData.map((item) => {
          return (
            <HistoryItem key={item.id} personalInfo={personalInfo} {...item} />
          );
        })}
    </div>
  );
};

export default React.memo(HistoryList);
