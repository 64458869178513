import React, { Suspense, useEffect, useMemo } from "react";
import { PagesRoutes } from "../../../features/navigation/constants";
import { matchRoutes, renderMatches, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadedPage } from "../../../features/navigation/components/LazyLoadedPage/LazyLoadedPage";
import FullScreenLoader from "../../../components/FullSreenLoader/FullScreenLoader";
import Home from "../../Home/Home";
import PageUnderDevelopment from "../PageUnderDevelopment/PageUnderDevelopment";
import StreamerPayment from "../../StreamerDashboard/pages/StreamerPayment/StreamerPayment";
import StreamerPersonalPayments from "../../StreamerDashboard/pages/StreamerPayment/components/StreamerPersonalPayments/StreamerPersonalPayments";
import StreamerPaymentList from "../../StreamerDashboard/pages/StreamerPayment/components/StreamerPaymentList/StreamerPaymentList";
import StreamerPersonalForm from "../../StreamerDashboard/pages/StreamerPersonal/components/StreamerPersonalForm/StreamerPersonalForm";
import StreamerPersonal from "../../StreamerDashboard/pages/StreamerPersonal/StreamerPersonal";
import StreamerHistory from "../../StreamerDashboard/pages/StreamerHistory/StreamerHistory";
import Transaction from "../../Transaction/Transaction";
import AgencyInformation from "../../AgencyDashboard/pages/AgencyInformation/AgencyInformation";
import CurrencyDetails from "../../AgencyDashboard/pages/CurrencyDetails/CurrencyDetails";
import AgencyHistory from "../../AgencyDashboard/pages/AgencyHistory/AgencyHistory";
import AgencyAnalytics from "../../AgencyDashboard/pages/AgencyAnalytics/AgencyAnalytics";
import AdminHistory from "../../AdminDashboard/pages/AdminHistory/AdminHistory";
import AdminBatch from "../../AdminDashboard/pages/AdminBatch/AdminBatch";
import Footer from "../../../components/Footer/Footer";
import styles from "./RootWithPageLayout.module.scss";
import Page403 from "../Page403/Page403";
import Page404 from "../Page404/Page404";
import Page500 from "../Page500/Page500";
import Terms from "../../Terms/Terms";

const RootWithPageLayout = () => {
  const location = useLocation();

  const { i18n } = useTranslation();

  const currentLang = i18n.language;

  const mainContentRoutesList = useMemo(() => {
    return [
      {
        path: PagesRoutes.COMMON.MAIN,
        element: <Home />,
      },
      {
        path: PagesRoutes.STREAMER.TRANSACTION,
        element: <Transaction />,
      },
      {
        path: PagesRoutes.COMMON.ABOUT,
        element: <PageUnderDevelopment />,
      },
      {
        path: PagesRoutes.COMMON.CONTACTS,
        element: <PageUnderDevelopment />,
      },
      {
        path: PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT,
        element: <StreamerPayment children={<StreamerPersonalPayments />} />,
      },
      {
        path: PagesRoutes.STREAMER.STREAMER_ADMIN_PAYMENT_LIST,
        element: <StreamerPayment children={<StreamerPaymentList />} />,
      },
      {
        path: PagesRoutes.STREAMER.STREAMER_ADMIN_PERSONAL,
        element: <StreamerPersonal children={<StreamerPersonalForm />} />,
      },
      {
        path: PagesRoutes.STREAMER.STREAMER_ADMIN_HISTORY,
        element: <StreamerHistory />,
      },
      {
        path: PagesRoutes.AGENCY.AGENCY_ADMIN_INFORMATION,
        element: <AgencyInformation />,
      },
      {
        path: PagesRoutes.AGENCY.AGENCY_ADMIN_CURRENCY_DETAILS,
        element: <CurrencyDetails />,
      },
      {
        path: PagesRoutes.AGENCY.AGENCY_ADMIN_TRANSACTION_HISTORY,
        element: <AgencyHistory />,
      },
      {
        path: PagesRoutes.AGENCY.AGENCY_ADMIN_ANALYTICS,
        element: <AgencyAnalytics />,
      },
      {
        path: PagesRoutes.ADMIN.ADMIN_TRANSACTIONS,
        element: <AdminHistory />,
      },
      {
        path: PagesRoutes.ADMIN.ADMIN_BATCH,
        element: <AdminBatch />,
      },
      {
        path: PagesRoutes.COMMON.PAGE_403,
        element: <Page403 />,
      },
      {
        path: PagesRoutes.COMMON.PAGE_404,
        element: <Page404 />,
      },
      {
        path: PagesRoutes.COMMON.PAGE_500,
        element: <Page500 />,
      },
      {
        path: PagesRoutes.COMMON.TERMS,
        element: <Terms />,
      },
    ];
  }, []);

  const mainContent = renderMatches(
    matchRoutes(mainContentRoutesList, location),
  );

  useEffect(() => {
    document.documentElement.dir = currentLang === "en" ? "ltr" : "rtl";
  }, [currentLang]);

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <div dir={currentLang === "en" ? "ltr" : "rtl"}>
        <div className={styles.container}>
          <LazyLoadedPage page={mainContent}></LazyLoadedPage>
        </div>
        <Footer />
      </div>
    </Suspense>
  );
};

export default RootWithPageLayout;
