import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  UpdateAdminTransactionStatusParams,
  UpdateAdminTransactionStatusResponse,
} from "./types";

export const updateAdminTransactionStatusApiMethod = ApiMethods.POST;

export const updateAdminTransactionStatusApi = (
  params: UpdateAdminTransactionStatusParams,
): Promise<UpdateAdminTransactionStatusResponse> =>
  appAxiosInstance[
    updateAdminTransactionStatusApiMethod
  ]<UpdateAdminTransactionStatusResponse>(
    ApiPaths.AdminPaths.updateAdminTransactionStatusApi,
    params,
  ).then((res) => res.data);
