import { createQueryKeys } from "@lukemorales/query-key-factory";
import { FetchAgencyApiParams } from "./api/fetchAgency/types";
import { fetchAgencyApi } from "./api/fetchAgency/fetchAgency";
import { fetchAgencyInfoApi } from "./api/fetchAgencyInfo/fetchAgencyInfo";
import { fetchCurrencyDetailsApi } from "./api/fetchCurrencyDetails/fetchCurrencyDetails";
import { fetchAgencyTransactionHistoryApi } from "./api/fetchAgencyTransactionHistory/fetchAgencyTransactionHistory";
import { fetchAgencyTransactionHistoryDetailsApi } from "./api/fetchAgencyTransactionHistoryDetails/fetchAgencyTransactionHistoryDetails";
import { FetchAgencyTransactionHistoryApiParams } from "./api/fetchAgencyTransactionHistory/types";
import { FetchAgencyInfoApiParams } from "./api/fetchAgencyInfo/types";
import { FetchAgencyTransactionHistoryDetailsApiParams } from "./api/fetchAgencyTransactionHistoryDetails/types";
import { FetchCurrencyDetailsApiParams } from "./api/fetchCurrencyDetails/types";
import { FetchAgencyPaymentChannelsApiParams } from "./api/fetchAgencyPaymentChannels/types";
import { fetchAgencyPaymentChannelsApi } from "./api/fetchAgencyPaymentChannels/fetchAgencyPaymentChannels";
import { fetchUsernamesApi } from "./api/fetchUsernames/fetchUsernames";
import { fetchAgencyTransactionStatusListApi } from "./api/fetchAgencyTransactionStatusList/fetchAgencyTransactionStatusList";

export const agencyQueries = createQueryKeys("agency", {
  agency: (params: FetchAgencyApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => (!!params ? fetchAgencyApi({ ...params }) : undefined),
    };
  },
  agencyInfo: (params: FetchAgencyInfoApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchAgencyInfoApi({ lang: params.lang }),
    };
  },
  currencyDetails: (params: FetchCurrencyDetailsApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchCurrencyDetailsApi({ lang: params.lang }),
    };
  },
  transactionHistory: (params: FetchAgencyTransactionHistoryApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchAgencyTransactionHistoryApi({ ...params }),
    };
  },
  transactionHistoryDetails: (
    params: FetchAgencyTransactionHistoryDetailsApiParams,
  ) => {
    return {
      queryKey: [params],
      queryFn: () =>
        fetchAgencyTransactionHistoryDetailsApi({
          id: params.id,
          lang: params.lang,
        }),
    };
  },
  agencyPaymentChannels: (params: FetchAgencyPaymentChannelsApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchAgencyPaymentChannelsApi(params),
    };
  },
  usernames: {
    queryKey: null,
    queryFn: fetchUsernamesApi,
  },
  statusList: {
    queryKey: null,
    queryFn: fetchAgencyTransactionStatusListApi,
  },
});
