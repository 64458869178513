import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchTransactionHistoryDetailsApiParams,
  FetchTransactionHistoryDetailsApiResponse,
} from "./types";

export const fetchTransactionHistoryDetailsApiMethod = ApiMethods.GET;

export const fetchTransactionHistoryDetailsApi = (
  params: FetchTransactionHistoryDetailsApiParams,
): Promise<FetchTransactionHistoryDetailsApiResponse> =>
  appAxiosInstance[
    fetchTransactionHistoryDetailsApiMethod
  ]<FetchTransactionHistoryDetailsApiResponse>(
    ApiPaths.UserPaths.fetchTransactionHistoryDetails,
    { params },
  ).then((res) => res.data);
