import React, { FC, useState } from "react";
import styles from "./CurrencyDetailsItem.module.scss";
import { AgencyCurrencyDetailType } from "../../../../../../features/agency/types";
import { Icons } from "../../../../../../constants/icons";
import cn from "classnames";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Input from "../../../../../../components/Input/Input";
import Button from "../../../../../../components/Button/Button";
import PaymentPicker from "../PaymentPicker/PaymentPicker";
import { PaymentType } from "../../../../../../features/payment/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCurrencyDetailApi } from "../../../../../../features/agency/api/updateCurrencyDetail/updateCurrencyDetail";
import { agencyQueries } from "../../../../../../features/agency/queries";
import { useTranslation } from "react-i18next";
import { useSuccessModal } from "../../../../../../components/modals/SuccessModal/useSuccessModal";
import { Checkbox } from "@mui/material";

type CurrencyDetailsItemProps = AgencyCurrencyDetailType;

type FormFieldsType = Pick<
  AgencyCurrencyDetailType,
  "commission" | "fxRate" | "paymentDetails"
>;

const CurrencyDetailsItem: FC<CurrencyDetailsItemProps> = (props) => {
  const { id } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedPayments, setSelectedPayments] = useState<PaymentType[]>(
    props.paymentChannels,
  );

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { openModal, successModalElement } = useSuccessModal({
    text: "Information successfully saved",
  });

  const { mutate: updateCurrencyDetail } = useMutation(
    updateCurrencyDetailApi,
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(
          agencyQueries.currencyDetails({ lang: i18n.language }).queryKey,
        );
        openModal();
      },
    },
  );

  const handleSelectPayment = (payment: PaymentType) => {
    setSelectedPayments((prevState) => [...prevState, payment]);
  };

  const handleRemovePayment = (payment: PaymentType) => {
    setSelectedPayments((prevState) =>
      prevState.filter((item) => {
        return item.id !== payment.id;
      }),
    );
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper__open]: isOpen,
      })}
    >
      <div onClick={() => setIsOpen(!isOpen)} className={styles.header}>
        <h4
          className={cn(styles.title, {
            [styles.title__open]: isOpen,
          })}
        >
          {props.currency.name}
        </h4>
        <Icons.ArrowCircle
          className={cn(styles.arrowIcon, {
            [styles.arrowIcon__open]: isOpen,
          })}
        />
      </div>
      {isOpen && (
        <div className={styles.content}>
          <Formik
            initialValues={{
              commission: props.commission,
              paymentDetails: props.paymentDetails,
              fxRate: props.fxRate,
            }}
            onSubmit={(
              values: FormFieldsType,
              { setSubmitting }: FormikHelpers<FormFieldsType>,
            ) => {
              updateCurrencyDetail({
                id: props.id,
                lang: i18n.language,
                fxRate: checked ? "Daily Bank Rate" : values.fxRate,
                paymentDetails: values.paymentDetails,
                commission: values.commission,
                paymentChannels: selectedPayments,
              });
            }}
          >
            {(props: FormikProps<FormFieldsType>) => (
              <Form>
                <div className={styles.form}>
                  <PaymentPicker
                    selectedPayments={selectedPayments}
                    handleSelectPayment={handleSelectPayment}
                    handleRemovePayment={handleRemovePayment}
                  />
                  <Field
                    name="paymentDetails"
                    as={Input}
                    labelText={t("agency.currency.paymentDetails")}
                    wrapperClassName={styles.inp}
                    editable={true}
                    disabled={true}
                  />
                  <div className={styles.inp}>
                    <Field
                      name="fxRate"
                      as={Input}
                      labelText={t("agency.currency.fxRate")}
                      wrapperClassName={styles.inp}
                      editable={!checked}
                      disabled={checked}
                    />
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        id={`${id}checkbox`}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <label htmlFor={`${id}checkbox`} className={styles.label}>
                        Daily Bank Rate
                      </label>
                    </div>
                  </div>
                  <Field
                    name="commission"
                    as={Input}
                    labelText={t("agency.currency.commission")}
                    wrapperClassName={styles.inp}
                    editable={true}
                    disabled={true}
                  />
                </div>
                <Button
                  variant={"secondary"}
                  type="submit"
                  className={styles.btn}
                >
                  {t("agency.currency.button")}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {successModalElement}
    </div>
  );
};

export default React.memo(CurrencyDetailsItem);
