import React from "react";
import { ModalProps } from "./Modal.types";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import styles from "./Modal.module.scss";
import cn from "classnames";

export const Modal = React.memo<ModalProps>((props) => {
  const {
    headerContent,
    footerContent,
    children,
    backdropClassName,
    containerClassName,
    headerClassName,
    contentClassName,
    footerClassName,
    contentRef,
    rootClassName,
    ...restDialogProps
  } = props;
  return (
    <Dialog
      // стиль поверхности диалога
      classes={{
        paper: cn(styles.container, containerClassName),
        root: styles.root,
      }}
      slotProps={{
        // стиль backdrop
        backdrop: { className: cn(styles.backdrop, backdropClassName) },
      }}
      fullWidth={false}
      {...restDialogProps}
    >
      {/* Необязатльный контент, который находится сверху и не скроллится.
      Для заголовка, кнопки закрытия и тд. */}
      <header className={cn(styles.header, headerClassName)}>
        {headerContent}
      </header>

      {/* Основной контент, который находится по-середине и скроллится*/}
      <DialogContent
        ref={contentRef}
        className={cn(styles.content, contentClassName)}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
});

Modal.displayName = "Modal";
