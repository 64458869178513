import { ReactNode } from "react";
import { Icons } from "../../constants/icons";
import { PagesRoutes } from "../../features/navigation/constants";

type AdminDashboardMenuItemType = {
  icon: ReactNode;
  text: string;
  to: string;
};

export const adminDashboardMenuData: AdminDashboardMenuItemType[] = [
  {
    icon: <Icons.Edit />,
    text: "agency.dashboard.history",
    to: PagesRoutes.ADMIN.ADMIN_TRANSACTIONS,
  },
  {
    icon: <Icons.Analytics />,
    text: "Batch Payment",
    to: PagesRoutes.ADMIN.ADMIN_BATCH,
  },
  {
    icon: <Icons.Home />,
    text: "agency.dashboard.home",
    to: PagesRoutes.COMMON.MAIN,
  },
];
