import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchAgencyInfoApiParams, FetchAgencyInfoApiResponse } from "./types";

export const fetchAgencyInfoApiMethod = ApiMethods.GET;

export const fetchAgencyInfoApi = (
  params: FetchAgencyInfoApiParams,
): Promise<FetchAgencyInfoApiResponse> =>
  appAxiosInstance[fetchAgencyInfoApiMethod]<FetchAgencyInfoApiResponse>(
    ApiPaths.AgencyPaths.fetchAgencyInfoApi,
    { params },
  ).then((res) => res.data);
