import React, { FC } from "react";
import Button, { ButtonProps } from "../Button/Button";
import { Link, LinkProps } from "react-router-dom";

type LinkButtonProps = { buttonClassName?: string } & LinkProps &
  Pick<ButtonProps, "variant" | "size" | "children">;

const LinkButton: FC<LinkButtonProps> = ({
  buttonClassName,
  variant,
  size,
  children,
  ...restProps
}) => {
  return (
    <Link {...restProps}>
      <Button className={buttonClassName} variant={variant} size={size}>
        {children}
      </Button>
    </Link>
  );
};

export default React.memo(LinkButton);
