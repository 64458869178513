import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  CreateTransactionApiParams,
  CreateTransactionApiResponse,
} from "./types";

export const createTransactionApiMethod = ApiMethods.POST;

export const createTransactionApi = (
  params: CreateTransactionApiParams,
): Promise<CreateTransactionApiResponse> =>
  appAxiosInstance[createTransactionApiMethod]<CreateTransactionApiResponse>(
    ApiPaths.TransactionPaths.createTransactionApi,
    params,
  ).then((res) => res.data);
