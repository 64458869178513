import React, { FC } from "react";
import { Modal } from "../../../../components/Modal/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import styles from "./AuthModal.module.scss";
import { Icons } from "../../../../constants/icons";
import TrovoButtonLink from "../TrovoButtonLink/TrovoButtonLink";
import { useModal } from "../../../../components/Modal/useModal";
import AgencyLoginModal from "../../../agency/components/AgencyLoginModal/AgencyLoginModal";
import { useTranslation } from "react-i18next";

type AuthModalProps = {} & Pick<ModalProps, "children" | "open" | "onClose">;

const AuthModal: FC<AuthModalProps> = (props) => {
  const { open, openModal, closeModal } = useModal();

  const { t } = useTranslation();

  const handleOpenAgencyModal = () => {
    props.onClose();
    openModal();
  };

  return (
    <>
      <Modal {...props} contentClassName={styles.wrapper}>
        <Icons.Logotype className={styles.logo} />
        <h3 className={styles.title}>{t("auth.title")}</h3>
        <div className={styles.content}>
          <TrovoButtonLink />
        </div>
        <div className={styles.footer}>
          <button
            onClick={handleOpenAgencyModal}
            className={styles.agencyLogin}
          >
            {t("auth.agency")}
          </button>
        </div>
      </Modal>
      <AgencyLoginModal open={open} onClose={closeModal} />
    </>
  );
};

export default AuthModal;
