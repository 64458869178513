import { createQueryKeys } from "@lukemorales/query-key-factory";
import { fetchPersonalInfoApi } from "./fetchPersonalInfo/fetchPersonalInfo";
import { fetchGemsBalanceApi } from "./fetchGemsBalance/fetchGemsBalance";
import { fetchTransactionHistoryApi } from "./fetchTransactionHistory/fetchTransactionHistory";
import { FetchTransactionHistoryDetailsApiParams } from "./fetchTransactionHistoryDetails/types";
import { fetchTransactionHistoryDetailsApi } from "./fetchTransactionHistoryDetails/fetchTransactionHistoryDetails";
import { FetchPersonalInfoApiParams } from "./fetchPersonalInfo/types";
import { FetchTransactionHistoryApiParams } from "./fetchTransactionHistory/types";
import { fetchUserTransactionStatusListApi } from "./fetchUserTransactionStatusList/fetchUserTransactionStatusList";

export const userQueries = createQueryKeys("user", {
  info: (params: FetchPersonalInfoApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchPersonalInfoApi(params),
    };
  },
  balance: {
    queryKey: null,
    queryFn: () => fetchGemsBalanceApi(),
  },
  transactionHistory: (params: FetchTransactionHistoryApiParams) => {
    return {
      queryKey: [params],
      queryFn: () => fetchTransactionHistoryApi(params),
    };
  },
  transactionHistoryDetails: (
    params: FetchTransactionHistoryDetailsApiParams,
  ) => {
    return {
      queryKey: [params],
      queryFn: () =>
        fetchTransactionHistoryDetailsApi({ id: params.id, lang: params.lang }),
    };
  },
  statusList: {
    queryKey: null,
    queryFn: fetchUserTransactionStatusListApi,
  },
});
