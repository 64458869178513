import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import { FetchGemsBalanceResponse } from "./types";

export const fetchGemsBalanceApiMethod = ApiMethods.GET;

export const fetchGemsBalanceApi = (): Promise<FetchGemsBalanceResponse> =>
  appAxiosInstance[fetchGemsBalanceApiMethod]<number>(
    ApiPaths.UserPaths.fetchGemsBalanceApi,
  ).then((res) => res.data);
