import React, { FC } from "react";
import QueryFilter from "../../../../../../components/QueryFilter/QueryFilter";

type BatchStatusFilterProps = {};

const filterData = [
  {
    text: "Success",
    value: "SUCCESS",
  },
  {
    text: "Pending",
    value: "INITIAL",
  },
];

const BatchStatusFilter: FC<BatchStatusFilterProps> = () => {
  return (
    <QueryFilter
      label={"Status"}
      queryName={"status"}
      emptyText={"All"}
      items={filterData}
    />
  );
};

export default React.memo(BatchStatusFilter);
