import {
  ApiMethods,
  ApiPaths,
  appAxiosInstance,
} from "../../../../utils/api/api";
import {
  FetchTransactionHistoryApiParams,
  FetchTransactionHistoryApiResponse,
} from "./types";

export const fetchTransactionHistoryApiMethod = ApiMethods.GET;

export const fetchTransactionHistoryApi = (
  params: FetchTransactionHistoryApiParams,
): Promise<FetchTransactionHistoryApiResponse> =>
  appAxiosInstance[
    fetchTransactionHistoryApiMethod
  ]<FetchTransactionHistoryApiResponse>(
    ApiPaths.UserPaths.fetchTransactionHistory,
    {
      params,
    },
  ).then((res) => res.data);
