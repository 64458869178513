import React from "react";
import { PropsWithChildren } from "react";
import { QueryClient } from "@tanstack/react-query";
import { StyledEngineProvider } from "@mui/material";
import { Router } from "./features/navigation/components/Router/Router";
import GlobalStates from "./components/GlobalStates/GlobalStates";
import { queryClientConfig } from "./utils/api/queryClientConfig";
import "./styles/index.scss";

export const CommonApp = (props: PropsWithChildren) => {
  const { children } = props;

  return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
};

const App = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: { ...queryClientConfig },
      }),
  );

  return (
    <GlobalStates queryClient={queryClient}>
      <CommonApp>
        <Router />
      </CommonApp>
    </GlobalStates>
  );
};

export default App;
